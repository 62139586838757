import { WhiteTruck } from '@c/cards/MXListProductCard';
import BaseModal from '@c/modals/BaseModal';
import ProductViewerModal from '@c/modals/ProductViewerModal';
import ProductConditions from '@c/ProductConditions';
import { useBuyerActionButtons } from '@features/shop';
import { ProductDocument } from '@models/product';
import { useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import { logInitCheckout } from '@util/analytics';
import { getCartItemsSubtotal } from '@util/firestore/cart';
import {
  getProductByIdNotClean,
  hasValidVariations,
} from '@util/firestore/products';
import { getEstimates } from '@util/firestore/shipengine/shipengine.service';
import { formatCurrency, getCartItemFromProductDocument } from '@util/index';
import { useAttributionContext } from 'context/AttributionContext';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';
import { useShoppingCart } from 'context/ShoppingCartContext';
import { useStripeContext } from 'context/StripeContext';
import { useToastContext } from 'context/ToastContext';
import dayjs from 'dayjs';
import MakeAnOfferButton from 'features/makeAnOffer/components/MakeAnOfferButton';
import { XIcon } from 'lucide-react';
import { Montserrat } from 'next/font/google';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { useState } from 'react';
import QuickViewMediaCarousel from './QuickViewMediaCarousel';

const montserrat = Montserrat({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
});

const QuickViewModal = ({
  product,
  isOpen,
  dismiss,
}: {
  product: ProductDocument;
  isOpen: boolean;
  dismiss: () => void;
}) => {
  const [openProductViewer, setOpenProductViewer] = useState(false);
  return (
    <>
      <BaseModal isOpen={isOpen} dismiss={dismiss} hideCloseIcon>
        <QuickViewContent
          product={product}
          dismiss={dismiss}
          setOpenProductViewer={setOpenProductViewer}
        />
      </BaseModal>

      <ProductViewerModal
        video={product.video}
        isOpen={openProductViewer}
        dismiss={() => setOpenProductViewer(false)}
        images={product.images}
      />
    </>
  );
};

export function QuickViewContent({
  product,
  setOpenProductViewer,
  dismiss,
}: {
  product: ProductDocument;
  setOpenProductViewer?: (open: boolean) => void;
  dismiss?: () => void;
}) {
  const { userDoc, user } = useAuth();
  const pathname = usePathname() ?? '';
  const { selectedVariations } = useBuyerActionButtons(product);
  const { showErrorToast } = useToastContext();

  const { increaseCartQty, canAddProductToCart, setCartOpen } =
    useShoppingCart();

  const { getAttribution } = useAttributionContext();

  const router = useRouter();

  const handleAddToCart = () => {
    if (userDoc && !user?.isAnonymous) {
      if (canAddProductToCart(product.id)) {
        if (hasValidVariations(product, selectedVariations)) {
          const attribution = getAttribution(product.id);
          const cartItem = getCartItemFromProductDocument(
            product,
            selectedVariations,
            attribution
          );

          increaseCartQty(cartItem, false);

          dismiss?.();
        } else {
          onAddToCartError();
        }
      } else {
        setCartOpen(true);
      }
    } else {
      router.push(`/login?redirect=${encodeURI(pathname ?? '')}`);
    }
  };

  const onAddToCartError = () => {
    if (!selectedVariations || selectedVariations.length === 0) {
      showErrorToast('Please select both Jersey and Pant sizes');
    } else if (
      product.category2 === 'Gear Combos' &&
      selectedVariations &&
      selectedVariations.length === 1
    ) {
      // find out which variation is missing
      const missingVariation = selectedVariations[0].is_number
        ? 'Jersey'
        : 'Pant';
      showErrorToast(`Please select both ${missingVariation} sizes`);
    }
  };

  const handleBuyNow = () => {
    if (userDoc && !user?.isAnonymous) {
      if (hasValidVariations(product, selectedVariations)) {
        if (canAddProductToCart(product.id)) {
          const cartItem = getCartItemFromProductDocument(
            product,
            selectedVariations
          );
          increaseCartQty(cartItem, true); // true to skip opening the cart drawer
        }
        logInitCheckout(
          [product],
          getCartItemsSubtotal([
            getCartItemFromProductDocument(product, selectedVariations),
          ]),
          userDoc?.uid ?? ''
        );
        router.push('/checkout');
      } else {
        onAddToCartError();
      }
    } else {
      router.push(`/login?redirect=${encodeURI(pathname ?? '')}`);
    }
  };

  const { data: deliveryDates } = useQuery({
    queryKey: ['deliveryDates', product.id, userDoc?.addresses.length],
    queryFn: async () => {
      let shipTo = userDoc?.addresses?.find((a) => a.is_default);
      if (!shipTo) shipTo = userDoc?.addresses?.[0];
      if (shipTo) {
        const productWithShipment = await getProductByIdNotClean(product.id);
        if (!productWithShipment?.shipment)
          return {
            earliest: dayjs().add(4, 'days').toISOString(), // next day shipping
            latest: dayjs().add(9, 'days').toISOString(), // 5 day shipping
          };
        const rates = await getEstimates({
          product_id: product.id,
          to_country_code: shipTo.country_code,
          to_postal_code: shipTo.postal_code ?? '',
        });
        // get earliest and latest delivery dates
        const earliest = rates.reduce((prev, curr) => {
          if (curr.estimated_delivery_date < prev.estimated_delivery_date)
            return curr;
          return prev;
        });
        const latest = rates.reduce((prev, curr) => {
          if (curr.estimated_delivery_date > prev.estimated_delivery_date)
            return curr;
          return prev;
        });
        return {
          earliest: earliest.estimated_delivery_date,
          latest: latest.estimated_delivery_date,
        };
      } else {
        return {
          earliest: dayjs().add(4, 'days').toISOString(), // next day shipping
          latest: dayjs().add(9, 'days').toISOString(), // 5 day shipping
        };
      }
    },
    enabled: !product.is_auction && !!userDoc?.addresses.length,
  });

  return (
    <>
      <div
        className={`${montserrat.className} flex flex-col gap-y-8 self-stretch p-8`}
      >
        <div className="flex gap-x-16 p-2">
          <QuickViewMediaCarousel product={product} />

          <div className="flex flex-col gap-y-8">
            <div className="flex w-full items-start justify-between gap-x-8">
              <h1 className="line-clamp-2 w-[35rem] text-[3.4rem] font-bold">
                {product.title}
              </h1>

              <XIcon className="h-12 w-12 cursor-pointer" onClick={dismiss} />
            </div>

            <div className="flex flex-col gap-y-2">
              <div className="flex items-center gap-x-6">
                <span className="text-[4.5rem] font-bold">
                  {formatCurrency(product.price)}
                </span>

                {product.is_flat_rate &&
                product.shipping_costs?.every(({ cost }) => cost === 0) &&
                !product.is_digital ? (
                  <div className="rounded-2xl bg-black px-4 py-2">
                    <div className="flex items-center gap-[.5rem]">
                      <WhiteTruck className={'h-9 w-9'} />

                      <span className="text-[2rem] font-semibold italic text-white">
                        Free Shipping
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>

              {product.on_sale && (
                <span className="text-[2.5rem] text-[#7F7F7F]">
                  Was {formatCurrency(product.og_price)}{' '}
                  <span className="font-bold text-brand-secondary">
                    (
                    {Math.round(
                      ((Number(product.og_price) - product.price) /
                        Number(product.og_price)) *
                        100
                    )}
                    % off)
                  </span>
                </span>
              )}
            </div>

            <ProductConditions
              product={product}
              deliveryDates={deliveryDates}
              toggleFitmentWarning={() => {}} // todo?
            />

            <div className="flex flex-col gap-y-4">
              <Button
                disabled={product.out_of_stock}
                onClick={handleBuyNow}
                type="secondary"
                text="Buy Now"
              />

              <Button
                disabled={product.out_of_stock}
                onClick={handleAddToCart}
                type="outline"
                text="Add to Cart"
              />

              <MakeAnOfferButton
                product={product}
                selectedVariations={selectedVariations ?? []}
              />
            </div>
          </div>
        </div>

        <div className="w-full border-t border-zinc-200 pt-8">
          <div className="flex w-full items-center justify-center">
            <Link
              href={`/product/${product.slug}`}
              className="flex items-start gap-x-4"
            >
              <span className="text-[2.2rem] font-semibold text-[#E20003]">
                View Listing
              </span>

              <ArrowTopRightIcon className="h-8 w-8" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuickViewModal;

const ArrowTopRightIcon = ({ className }: { className?: string }) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.79289 11.2929L3.08579 12L4.5 13.4142L5.20711 12.7071L3.79289 11.2929ZM5.20711 12.7071L13.2071 4.70711L11.7929 3.29289L3.79289 11.2929L5.20711 12.7071Z"
      fill="#C41719"
    />
    <path
      d="M6 4H12.5V10.5"
      stroke="#C41719"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);
