'use client';
import { CloseIcon, CurationsIcon } from '@c/icons';
import { LogoWithoutText } from '@c/icons/logoWithoutText';
import ProductViewerModal from '@c/modals/ProductViewerModal';
import { ChatDocument, ChatMessageDocument, SupportState } from '@models/chat';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Avatar from '@ui/Avatar';
import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import {
  flagChat,
  getChatById,
  hideChat,
  markAsUnread,
  resolveChat,
  uploadChatImage,
} from '@util/firestore/messages';
import { getOffer } from '@util/firestore/offers';
import { getProductById } from '@util/firestore/products';
import {
  cleanUser,
  getPublicUserDoc,
  getUserById,
} from '@util/firestore/users';
import { StatusLabel, useOnlineStatus } from '@util/hooks/useOnlineStatus';
import { getCdnImageUrls } from '@util/urlHelpers';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';
import { useToastContext } from 'context/ToastContext';
import { onSnapshot } from 'firebase/firestore';
import Link from 'next/link';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import MessageBubble from './MessageBubble';
import { ArrowUpIcon, Router } from 'lucide-react';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useRouter } from 'next/navigation';
import SummarizeChat from './SummarizeChat';

export interface MessageWindowProps {
  goBack?: () => void;
  chat?: ChatDocument;
  previewSlot?: React.ReactNode;
}

export default function MessageWindow({
  goBack,
  chat: initialChat,
  previewSlot,
}: MessageWindowProps) {
  const TEXTAREA_HEIGHT = '4.75rem'; // Do not change this value to anything smaller unless Grammarly changes their min height requirements. Our support team uses Grammarly to improve their writing and if the textarea is too small, Grammarly doesn't work.
  const [chat, setChat] = useState<ChatDocument | undefined>(initialChat);
  const [loadingButton, setLoadingButton] = useState('');
  const inputId = useRef<string>(Math.random().toString(36).substring(7));
  const { sendMessage } = useChat();
  const queryClient = useQueryClient();
  const [message, setMessage] = React.useState<string>('');
  const { userDoc } = useAuth();
  const messageWindowRef = React.useRef<HTMLDivElement>(null);
  const [fileList, setFileList] = React.useState<FileList | undefined>();
  const [fallBackImages, setFallBackImages] = React.useState(
    new Map<string, string[]>()
  );
  const [fallbackByURLImages, setFallbackByURLImages] = React.useState<
    Map<string, string>
  >(new Map());
  const [loadingImage, setLoadingImage] = React.useState(false);
  const [imageModal, setImageModal] = React.useState<{
    isOpen: boolean;
    index: number;
    images: { full: string; thumb: string }[];
  }>({ isOpen: false, index: 0, images: [] });
  const { data: offer } = useQuery({
    queryKey: ['offer', chat?.offer_id ?? ''],
    queryFn: () => getOffer(chat?.offer_id ?? ''),
    enabled: !!chat?.offer_id,
  });
  const { data: product } = useQuery({
    queryKey: ['product', chat?.product_id ?? ''],
    queryFn: () => getProductById(chat?.product_id ?? ''),
    enabled: !!offer?.product_id,
  });
  const router = useRouter();

  const recipient = !chat
    ? undefined
    : chat.buyer_id === userDoc?.uid
    ? chat.seller_id
    : chat.buyer_id;

  const { data: recipientDoc } = useQuery({
    queryKey: ['publicUser', recipient, userDoc?.roles?.support],
    queryFn: async () =>
      userDoc?.roles?.support
        ? cleanUser((await getUserById(recipient!))!)
        : getPublicUserDoc({
            uid: recipient!,
          }),
    enabled: !!recipient,
  });

  const handleSubmit = async () => {
    if (!userDoc || !chat?.id) return;
    const messageDoc = {
      uid: userDoc.uid,
      content: message,
      created_at: new Date().getTime(),
    } as ChatMessageDocument;
    if (messageDoc.content) {
      sendMessage(messageDoc, chat.id, product ?? undefined);
    }
    // Send another message with the image url
    const files = Array.from(fileList ?? []);
    const dataUrls: string[] = [];
    if (files?.length) {
      files.forEach((file) => {
        const messageDocCopy = { ...messageDoc };
        uploadChatImage(file, userDoc.uid, chat.id).then((res) => {
          const { path, download_url } = res;
          const { full, thumb } = getCdnImageUrls(path);
          messageDocCopy.thumbnail = thumb;
          messageDocCopy.content = full;
          messageDocCopy.is_img = true;
          messageDocCopy.download_url = download_url;
          sendMessage(messageDocCopy, chat.id!);
          setFallbackByURLImages((prev) => new Map(prev).set(thumb, dataUrl));
          const dataUrl = URL.createObjectURL(file);
          dataUrls.push(dataUrl);
        });
        setLoadingImage(true);
      });
    }
    setFallBackImages((prev) => new Map(prev).set(chat.id!, dataUrls));

    setMessage('');
    setFileList(undefined);
    // scroll to bottom
    setTimeout(() => {
      if (messageWindowRef.current) {
        messageWindowRef.current.scrollTop =
          messageWindowRef.current.scrollHeight;
      }
    });

    // reset the height of the text input
    if (textInput.current) {
      textInput.current.style.height = TEXTAREA_HEIGHT;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (messageWindowRef.current) {
        messageWindowRef.current.scrollTop =
          messageWindowRef.current.scrollHeight;
      }
    });
  }, []);

  const onImageClick = (src: string) => {
    let index: number | undefined;
    const images: { thumb: string; full: string }[] = [];
    messages
      ?.filter((m) => m.is_img)
      .forEach((msg, i) => {
        if (index === undefined && src === msg.thumbnail) index = i;
        images.push({ thumb: msg.thumbnail ?? msg.content, full: msg.content });
      });
    if (index === undefined) index = 0;
    setImageModal({ images, index, isOpen: true });
  };

  const { status } = useOnlineStatus(recipientDoc?.uid ?? '');

  // subscribe to real time messages
  const [messages, setMessages] = useState<ChatMessageDocument[] | null>(null);
  const chatsQuery = useMemo(() => getChatById(chat?.id ?? ''), [chat]);

  useEffect(() => {
    const unsubscribe = onSnapshot(chatsQuery, (snapshot) => {
      const data = snapshot.data();
      setMessages(data?.messages ?? []);
      setLoadingImage(false);
      setTimeout(() => {
        if (messageWindowRef.current) {
          messageWindowRef.current.scrollTop =
            messageWindowRef.current.scrollHeight;
        }
      });
    });
    return () => {
      unsubscribe();
    };
  }, [chatsQuery]);

  const textInput = useRef<HTMLTextAreaElement | null>(null);
  const { showSuccessToast, showErrorToast } = useToastContext();

  if (!userDoc || !chat) return null;

  return (
    <div className="flex h-full w-full flex-col @container ">
      <div className="border-b border-brand-lightest-gray shadow-sm">
        <div className="flex justify-between gap-[1.6rem] p-[1.6rem]">
          <div className="flex w-full">
            <div className="flex w-full flex-col gap-[1.6rem] gap-x-4 sm:flex-row ">
              {recipientDoc &&
                recipientDoc.uid !== process.env.NEXT_PUBLIC_SUPPORT_ID && (
                  <div className="flex items-center gap-x-4">
                    {goBack && (
                      <button
                        className="border-none bg-inherit outline-none"
                        type="button"
                      >
                        <ArrowLeftIcon
                          width={24}
                          height={24}
                          className="text-black"
                          onClick={goBack}
                        />
                      </button>
                    )}

                    <Link
                      className="flex items-center gap-x-4"
                      href={`/profile/${recipientDoc.username_slug}`}
                    >
                      <Avatar size="medium" user={recipientDoc} showBadge />

                      <div className="flex flex-col gap-y-2">
                        <div>
                          <h3 className="text-[1.8rem] font-semibold">
                            {recipientDoc.username}
                          </h3>
                        </div>

                        <StatusLabel
                          status={status}
                          hide_status={recipientDoc.hide_status}
                          showLastLogin={userDoc?.roles?.support}
                          recipientDocUid={recipientDoc.uid}
                        />
                      </div>
                    </Link>
                  </div>
                )}

              {recipientDoc &&
                recipientDoc.uid === process.env.NEXT_PUBLIC_SUPPORT_ID && (
                  <div className="flex w-full items-center justify-center gap-x-4">
                    {goBack && (
                      <button type="button" onClick={goBack}>
                        <ArrowLeftIcon className="absolute left-6 h-10 w-10 text-black" />
                      </button>
                    )}
                    <span className="flex flex-col items-center">
                      <h3 className="font-semibold">MX Locker Support</h3>
                      <span>Our agents typically answer within 24 hours</span>
                    </span>
                  </div>
                )}

              {userDoc?.roles?.support && (
                <div className="flex flex-nowrap items-center gap-[1.6rem] overflow-x-scroll pr-[4.8rem]">
                  {userDoc?.roles?.admin && (
                    <>
                      <Button
                        height="small"
                        type="tertiary"
                        width="small"
                        onClick={() => {
                          window.navigator.clipboard.writeText(
                            recipientDoc?.uid ?? ''
                          );
                          showSuccessToast('Copied to clipboard');
                        }}
                        text="Copy UID"
                      />
                      <Button
                        height="small"
                        type="tertiary"
                        width="small"
                        text="Backend"
                        href={`https://console.firebase.google.com/project/${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}/firestore/data/~2Fmessages~2F${chat.id}`}
                        target="_blank"
                      />
                    </>
                  )}
                  <Button
                    height="small"
                    type="tertiary"
                    width="small"
                    text={chat.flagged ? 'Unflag' : 'Flag'}
                    onClick={async () => {
                      await flagChat(chat.id!, !chat.flagged);
                      queryClient.invalidateQueries({
                        queryKey: ['adminFlaggedChats'],
                      });
                      setChat({ ...chat, flagged: !chat.flagged });
                    }}
                  />
                  {process.env.NEXT_PUBLIC_SUPPORT_ID && (
                    <Button
                      height="small"
                      type="tertiary"
                      width="small"
                      text={'Mark as Unread'}
                      loading={loadingButton === 'unread'}
                      onClick={async () => {
                        if (!process.env.NEXT_PUBLIC_SUPPORT_ID) return;
                        try {
                          setLoadingButton('unread');
                          await markAsUnread(
                            chat.id,
                            process.env.NEXT_PUBLIC_SUPPORT_ID
                          );
                          showSuccessToast('Marked as unread');
                        } catch (e) {
                          alert('Error marking as unread');
                        } finally {
                          setLoadingButton('');
                        }
                      }}
                    />
                  )}
                  {chat.case_num &&
                    ![SupportState.Resolved, SupportState.Cancelled].includes(
                      chat.support_state ?? 0
                    ) && (
                      <Button
                        height="small"
                        type="secondary"
                        width="small"
                        text="Resolve"
                        loading={loadingButton === 'Resolve'}
                        onClick={() => {
                          setLoadingButton('Resolve');
                          resolveChat(chat.id!, userDoc?.uid!)
                            .then(() => {
                              showSuccessToast('Resolved');
                              setLoadingButton('');
                              queryClient.invalidateQueries({
                                queryKey: ['adminBuyerChats'],
                              });
                              queryClient.invalidateQueries({
                                queryKey: ['adminSellerChats'],
                              });
                            })
                            .catch((e) => {
                              setLoadingButton('');
                              showErrorToast((e as Error).message);
                            });
                        }}
                      />
                    )}

                  <Button
                    height="small"
                    type="secondary"
                    width="small"
                    text="Hide"
                    onClick={() =>
                      hideChat(chat.id!, userDoc.uid).then(() => {
                        queryClient.invalidateQueries({
                          queryKey: ['adminBuyerChats'],
                        });
                        queryClient.invalidateQueries({
                          queryKey: ['adminSellerChats'],
                        });
                      })
                    }
                  />

                  <Button
                    height="small"
                    type="tertiary"
                    width="small"
                    text="Share"
                    onClick={() =>
                      // copy to clipboard
                      navigator.clipboard
                        .writeText(
                          `${window.location.origin}/dashboard/messages/${chat.id}`
                        )
                        .then(() => {
                          showSuccessToast('Copied to clipboard');
                        })
                    }
                  />
                  <SummarizeChat chat={chat} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="block @4xl:hidden">
        {!!previewSlot && (
          <>
            <hr />
            {previewSlot}
          </>
        )}
      </div>

      {/* messages */}
      <div
        ref={messageWindowRef}
        className="relative  flex h-full w-full overflow-y-scroll @container"
      >
        <div className="relative flex w-full grow flex-col justify-start pb-[.6rem] pl-[1.6rem] pr-[.6rem] pt-4">
          {/* <p className="text-brand-gray text-[1.3rem] sticky top-5 self-center">
            20 NOV
          </p> */}

          <div className="mx-auto flex items-center justify-center gap-[1.6rem] rounded-[2rem] bg-gray-100 px-6 py-[1.6rem] text-[1.375rem]">
            <div>
              <LogoWithoutText className="h-[3rem] w-[3rem] lg:h-[2.8rem] lg:w-[2.8rem]" />
            </div>

            {product?.category === 'Dirt Bikes'
              ? `MX Locker is not responsible for the sale of dirt bikes. Beware of scammers when selling outside the platform.`
              : `To protect your payment, always communicate and pay through the MX Locker website or app.`}
          </div>

          <div className="grow">
            {messages?.map((message, i) => {
              const showDate =
                i === 0 ||
                new Date(message.created_at).toLocaleDateString() !==
                  new Date(messages[i - 1].created_at).toLocaleDateString();
              return (
                <React.Fragment key={message.created_at + '-' + i}>
                  {showDate && (
                    <div className="flex w-full items-center justify-center pb-4 pt-8">
                      <p className="sticky top-5 self-center text-[1.5rem] text-brand-gray">
                        {new Date(message.created_at).toLocaleDateString(
                          undefined,
                          {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          }
                        )}
                      </p>
                    </div>
                  )}

                  <MessageBubble
                    chat={chat}
                    chatMessage={message}
                    fallbackImg={fallbackByURLImages.get(
                      message.thumbnail ?? ''
                    )}
                    realtimeMessages={messages}
                    onImageClick={(src) => onImageClick(src)}
                  />
                </React.Fragment>
              );
            })}
            {loadingImage &&
              fallBackImages.get(chat.id!)?.map((dataUrl) => (
                <MessageBubble
                  chat={chat}
                  key="loading-image"
                  chatMessage={{
                    created_at: Date.now(),
                    content: '',
                    thumbnail: dataUrl,
                    is_img: true,
                    uid: userDoc.uid,
                    is_auto: false,
                  }}
                  onImageClick={(src) => onImageClick(src)}
                />
              ))}
            {chat.is_expert &&
              messages?.every((msg) => msg.uid === userDoc.uid) && (
                <span className=" flex w-full grow items-center justify-center gap-4 text-[1.2rem] font-medium text-brand-dark-gray">
                  <CurationsIcon />
                  Experts usually respond within a few hours
                </span>
              )}
          </div>
          {imageModal.isOpen && (
            <ProductViewerModal
              isOpen={imageModal.isOpen}
              dismiss={() => setImageModal({ ...imageModal, isOpen: false })}
              images={imageModal.images}
              initialImage={imageModal.index}
            />
          )}
          {offer?.state === 2 && (
            <span className="py-6 text-center text-zinc-500">
              Offer has ended.{' '}
              {offer.seller_id === userDoc.uid ? 'You' : 'Seller'} declined the
              offer.
            </span>
          )}

          <div className="sticky bottom-0 bg-brand-white py-8">
            <div
              className="flex h-[6rem] max-h-[13.75rem] items-center gap-[1.6rem] border-[1.5px] border-black bg-brand-darker-white px-6 transition-[border-radius] duration-200"
              style={{
                borderRadius:
                  textInput.current?.scrollHeight &&
                  textInput.current.scrollHeight > 50
                    ? '2rem'
                    : '37.5rem',
                height: textInput.current?.scrollHeight
                  ? Math.max(textInput.current.scrollHeight + 10, 50) + 'px'
                  : '5.5rem',
              }}
            >
              {fileList ? (
                <div className="relative" id="preview-list">
                  <div className="h-[6rem] min-h-[6rem] w-[6rem] min-w-[6rem]">
                    {Array.from(fileList).map((file, index) => (
                      <SafeImage
                        key={file.name}
                        src={URL.createObjectURL(file)}
                        alt="preview"
                        height={64}
                        width={64}
                        className="absolute left-0 top-0 h-[6rem] min-h-[6rem] w-[6rem] min-w-[6rem] rounded-2xl object-cover"
                        style={{
                          // rotate slightly based on index
                          transform: `rotate(${
                            index % 2 === 0 ? -index * 10 : index * 10
                          }deg)`,
                          // z index based on index
                          zIndex: 100 - index,
                        }}
                      />
                    ))}
                  </div>

                  <button
                    className="absolute right-0 top-0 z-[100] flex h-[2.4rem] w-[2.4rem] items-center justify-center rounded-full bg-brand-red text-white"
                    onClick={() => {
                      setFileList(undefined);
                    }}
                  >
                    <CloseIcon />
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => {
                    const input = document.querySelector(
                      `#file-input-${inputId.current}`
                    ) as HTMLInputElement;
                    input.click();
                  }}
                  className="h-[6rem]"
                >
                  <div className="relative">
                    <ImagesIcon className="h-8 w-8" />
                  </div>
                </button>
              )}
              <textarea
                ref={textInput}
                className={`relative flex max-h-[13rem] w-full grow resize-none items-start justify-between gap-[0.8rem] overflow-hidden bg-inherit px-[2rem] py-0.5 pt-[1rem] text-start leading-[1.2] transition-[border-radius] duration-200 sm:pt-[1.3rem]`}
                style={{
                  border: 'none',
                  outline: 'none',
                  height: TEXTAREA_HEIGHT,
                }}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  e.target.style.height = TEXTAREA_HEIGHT;
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
                placeholder={'Write your message here...'}
                onKeyDown={(e) => {
                  if (e.key !== 'Enter') return;
                  if (e.shiftKey) return;

                  e.preventDefault();
                  handleSubmit();
                }}
                autoFocus
                onFocus={(e) =>
                  e.target.setSelectionRange(
                    e.target.value.length,
                    e.target.value.length
                  )
                }
              />
              <input
                id={`file-input-${inputId.current}`}
                type="file"
                accept="image/*"
                className="hidden"
                multiple={true}
                onChange={(e) => {
                  if (!e.target.files) return;
                  setFileList(e.target.files);
                }}
              />

              <button
                type="button"
                className={`flex aspect-square h-[4.4rem] flex-col items-center justify-center rounded-full border-2 text-white transition-colors ${
                  message.length ? 'bg-brand-secondary' : 'bg-brand-gray'
                }`}
                onClick={handleSubmit}
              >
                <ArrowUpIcon />
              </button>
            </div>
          </div>
        </div>
        <div
          className="sticky right-0 top-0 hidden @4xl:block"
          style={{ zoom: 0.8 }}
        >
          {previewSlot}
        </div>
      </div>
    </div>
  );
}

const ImagesIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.5 3.5V3C13.4988 2.60253 13.3404 2.22167 13.0594 1.94061C12.7783 1.65956 12.3975 1.50115 12 1.5H2C1.60253 1.50115 1.22167 1.65956 0.940615 1.94061C0.65956 2.22167 0.501154 2.60253 0.5 3V11C0.501154 11.3975 0.65956 11.7783 0.940615 12.0594C1.22167 12.3404 1.60253 12.4988 2 12.5H2.5"
        stroke="#7F7F7F"
        strokeLinejoin="round"
      />
      <path
        d="M14.0628 4H4.43719C3.64345 4 3 4.64345 3 5.43719V13.0628C3 13.8565 3.64345 14.5 4.43719 14.5H14.0628C14.8565 14.5 15.5 13.8565 15.5 13.0628V5.43719C15.5 4.64345 14.8565 4 14.0628 4Z"
        stroke="#7F7F7F"
        strokeLinejoin="round"
      />
      <path
        d="M11.6542 7.81953C12.1852 7.81953 12.6158 7.3921 12.6158 6.86484C12.6158 6.33758 12.1852 5.91016 11.6542 5.91016C11.1231 5.91016 10.6926 6.33758 10.6926 6.86484C10.6926 7.3921 11.1231 7.81953 11.6542 7.81953Z"
        stroke="#7F7F7F"
        strokeMiterlimit="10"
      />
      <path
        d="M10.6922 11.631L7.96875 8.93133C7.79465 8.75932 7.56178 8.65976 7.31714 8.65274C7.0725 8.64572 6.8343 8.73177 6.65063 8.89352L3 12.1145"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.28906 14.501L11.995 10.822C12.1655 10.6538 12.3923 10.5547 12.6316 10.544C12.8709 10.5332 13.1056 10.6114 13.2906 10.7635L15.5006 12.592"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
