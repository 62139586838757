import {
  safePolygon,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react-dom-interactions';
import SafeImage from '@ui/SafeImage';
import { getCDNUrl } from '@util/index';
import { motion, useAnimationControls } from 'framer-motion';
import { UserDocument } from 'models/user';
import Link from 'next/link';
import React, { useState } from 'react';
import categories from './utils/categories';
import { MotionDiv, MotionSpan } from 'motion';

const NavMenu = ({
  label,
  href,
  userDoc,
  children,
}: {
  label: string;
  href: string;
  userDoc?: UserDocument | null | undefined;
  children: React.ReactNode;
  componentIndex: number;
}) => {
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const controls = useAnimationControls();

  const { y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: 'bottom',
  });

  const { getReferenceProps } = useInteractions([
    useHover(context, { handleClose: safePolygon(), enabled: !clicked }),
    useDismiss(context, {
      // @ts-ignore
      referencePointerDown: true,
    }),
  ]);

  const variants = {
    open: { opacity: 1, height: 'auto', zIndex: 10 },
    closed: { opacity: 0, height: 0, zIndex: -1 },
  };

  const getLabel = () => {
    if (userDoc) {
      if (label === 'Bike Parts') {
        return 'Dirt Bike Parts';
      }

      return label;
    }

    return label;
  };

  const getCategoryColor = () => {
    const category = categories.find((c) => c.label === label);

    if (category) {
      return category.color;
    }

    return '#5B4040';
  };

  const getCategory = () => {
    const category = categories.find((c) => c.label === label);

    if (category) {
      return category;
    }

    return categories[0];
  };

  const ImageComponent = ({ src }: { src: string }) => {
    return (
      <SafeImage
        src={src}
        alt={label.toLowerCase()}
        className={`h-0 w-0 object-cover opacity-0 transition-all duration-300 ease-in-out group-hover:h-full group-hover:w-16 group-hover:opacity-100 ${
          label === 'Snow' ? '' : 'object-right'
        }`}
      />
    );
  };

  return (
    <div
      className={`group flex w-max items-center transition-all duration-300 ease-linear hover:mx-4 hover:gap-x-4 hover:bg-brand-darker-white`}
      onMouseEnter={() => controls.start({ opacity: 1, paddingRight: 1 })}
      onMouseLeave={() => {
        setOpen(false);
        setClicked(false);
        controls.start({ opacity: 0, paddingRight: 0 });
      }}
      onClick={() => {
        setOpen(false);
        setClicked(true);
      }}
    >
      <Link
        href={href}
        className={`flex h-full items-center text-[1.4rem]`}
        ref={reference}
      >
        <MotionDiv
          className={`h-16 w-auto`}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          animate={controls}
        >
          <ImageComponent src={getCDNUrl(getCategory().image ?? '')} />
        </MotionDiv>

        <MotionSpan
          {...getReferenceProps()}
          className="transition-all duration-150 ease-linear group-hover:pl-3 group-hover:pr-5"
        >
          {getLabel()}
        </MotionSpan>
      </Link>

      <MotionDiv
        initial={variants.closed}
        ref={floating}
        animate={open ? 'open' : 'closed'}
        variants={variants}
        exit={{ height: 0 }}
        transition={{ duration: 0.3 }}
        style={{
          top: (y ?? 0) + 10,
          left: 0,
          position: strategy,
          border: `4px solid ${getCategoryColor()}`,
        }}
        className="absolute -ml-4 -mt-5 max-h-[calc(100vh_-_13rem)] w-screen overflow-hidden overflow-y-auto border-t-4 bg-white"
      >
        <div>
          <div className="flex gap-x-4">
            <Link
              href={href}
              className="flex h-auto w-[40rem] flex-col justify-between"
              style={{ backgroundColor: getCategoryColor() }}
            >
              <div className="flex w-full justify-center pt-16 text-center">
                <span className="text-[2rem] font-semibold capitalize text-white">
                  Shop {label}
                </span>
              </div>

              <div className="bottom-0 left-0 flex">
                <SafeImage
                  src={getCDNUrl(getCategory().image ?? '')}
                  className="h-[30rem] w-full object-cover"
                  alt="Dirt Bikes"
                />
              </div>
            </Link>

            {children}
          </div>
        </div>
      </MotionDiv>
      {open && (
        <div className="fixed left-0 top-[15rem] z-0 h-screen w-screen bg-black/50" />
      )}
    </div>
  );
};

export default NavMenu;
