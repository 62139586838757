import { Counter } from '@c/counter';
import { TrashIcon } from '@heroicons/react/20/solid';
import { getCartItemFromProductDocument } from '@util/index';
import { CartItem } from '@util/types/firestore/carts';
import { useShoppingCart } from 'context/ShoppingCartContext';
import { ProductDocument } from 'models/product';

export const CartItemQuantity = ({
  product,
  cartItem,
}: {
  product: ProductDocument;
  cartItem: CartItem;
}) => {
  const { increaseCartQty, decreaseCartQty, removeFromCart } =
    useShoppingCart();

  return (
    <div className="flex items-center gap-4">
      <Counter
        val={cartItem.qty}
        increment={() => increaseCartQty(cartItem, false)}
        decrement={() => decreaseCartQty(product.id)}
      />
      <button
        onClick={() => removeFromCart(product.id)}
        className="text-brand-gray hover:text-brand-secondary"
      >
        <TrashIcon className="h-12 w-12" />
      </button>
    </div>
  );
};
