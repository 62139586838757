import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import { prefix } from '@util/imagePrefix';

export default function ScrubDesignz() {
  return (
    <div className="bg-[#F6F6F6] px-[2rem] py-[3rem] lg:bg-[#F1F1F1] lg:px-[15rem] lg:py-[8rem]">
      <div className="h-full w-full">
        <div className="flex flex-col lg:flex lg:h-full lg:w-full lg:flex-row lg:items-center lg:rounded-[3rem]">
          <SafeImage
            src={`${prefix}/assets/scrub-designz-home_1920x2560.jpg`}
            alt="ScrubDesignz"
            className="h-[17.5rem] w-full rounded-tl-[3rem] rounded-tr-[3rem] object-cover lg:h-[38.3rem] lg:w-1/2 lg:rounded-bl-[3rem] lg:rounded-tr-none"
            height={1500}
            width={850}
          />

          <div className="h-full w-full rounded-bl-[3rem] rounded-br-[3rem] bg-white p-[2rem] lg:h-[38.3rem] lg:w-1/2 lg:rounded-bl-none lg:rounded-tr-[3rem] lg:p-[7.5rem]">
            <div className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-2 lg:gap-y-6">
                <h3 className="text-[1.8rem] font-semibold text-[#7F7F7F]">
                  Featured Seller
                </h3>

                <h3 className="text-[2.5rem] font-bold text-black lg:text-[3rem]">
                  <span className="lg:hidden">
                    Personalize your ride with Scrubdesignz.
                  </span>
                  <span className="hidden lg:block">ScrubDesignz</span>
                </h3>
              </div>

              <p className="text-[1.8rem] font-medium text-[#444444]">
                <span className="lg:hidden">
                  Make your bike truly yours with unique designs tailored to
                  your preferences.
                </span>
                <span className="hidden lg:block">
                  Design your own kit with your name and number. Featuring
                  one-of-a-kind graphics, complete plastic kits, and
                  high-quality seat covers to give your ride a personalized
                  look.
                </span>
              </p>

              <Button
                text="Shop Now"
                className="w-fit !rounded-[1.5rem] !text-[2.1rem] lg:!text-[1.8rem]"
                type="secondary"
                href={'/profile/scrubdesignz'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
