'use client';

import { XIcon } from 'lucide-react';
import BaseModal from './BaseModal';
import Button from '@ui/Button';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  helpText?: string;
}

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  helpText,
}: Props) => {
  return (
    <BaseModal
      isOpen={isOpen}
      dismiss={onClose}
      hideCloseIcon
      className="!min-h-0"
    >
      <div className="flex h-full grow flex-col justify-evenly p-6">
        <div className="flex w-full items-center justify-between">
          <h1 className="text-[2rem] font-semibold text-black">{title}</h1>

          <button type="button" aria-label="Close modal" onClick={onClose}>
            <XIcon className="h-10 w-10 text-black" />
          </button>
        </div>

        {helpText && (
          <p className="max-w-[40rem] py-8 text-[1.8rem] text-zinc-600">
            {helpText}
          </p>
        )}

        <div className="mt-auto flex justify-end gap-x-8">
          <button
            type="button"
            className="text-[1.8rem] text-black"
            onClick={onClose}
          >
            Cancel
          </button>

          <Button
            type="secondary"
            text="Confirm"
            onClick={onConfirm}
            className="!font-semibold"
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default ConfirmationModal;
