import FormTextarea from '@c/forms/controls/FormTextarea';
import { SendIcon, ShieldCheckIcon } from '@c/icons';
import ProductInfo from '@c/products/ProductInfo';
import { ChatDocument, ChatInput, ChatMessageDocument } from '@models/chat';
import { OrderDocument } from '@models/order';
import { UserDocument } from '@models/user';
import { useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import {
  createChat,
  sendMessageToChat,
} from '@util/firestore/messages/messages.service';
import { getProductById } from '@util/firestore/products';
import { getPublicUserDoc, getUserDocument } from '@util/firestore/users';
import useRealtimeChats from '@util/hooks/useRealtimeChats';
import { isMobile } from '@util/index';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';
import BaseModal from './BaseModal';
import { useToastContext } from 'context/ToastContext';

interface ChatModalProps {
  isOpen: boolean;
  dismiss: () => void;
  recipientUid: string;
  productId?: string;
  order?: OrderDocument;
}

function ChatModal({
  isOpen,
  dismiss,
  recipientUid,
  productId,
  order,
}: ChatModalProps) {
  const { userDoc, user } = useAuth();
  const { data: recipient } = useQuery({
    queryKey: ['publicUser', recipientUid],
    queryFn: () => getPublicUserDoc({ uid: recipientUid }),
    enabled: !!recipientUid,
  });

  // ADMIN QUERY ONLY
  const { data } = useQuery({
    queryKey: ['user-admin', recipientUid],
    queryFn: () => getUserDocument({ uid: recipientUid }),
    enabled: !!recipientUid && userDoc?.roles?.admin,
  });
  const { data: product } = useQuery({
    queryKey: ['product', productId],
    queryFn: () => getProductById(productId!),
    enabled: !!productId,
  });
  const { realtimeChats } = useRealtimeChats(userDoc?.uid);

  const [message, setMessage] = React.useState('');
  const { openChatDrawer } = useChat();
  const router = useRouter();
  const pathname = usePathname();
  const { showErrorToast } = useToastContext();

  const getSellerId = () => {
    // if productid, check if user is seller or buyer
    if (productId) {
      return product?.seller_id === userDoc?.uid ? userDoc?.uid : recipientUid;
    }
    // if orderid, check if user is seller or buyer
    if (order) {
      return order.buyer_id === userDoc?.uid ? recipientUid : userDoc?.uid;
    }
    // if no productid or orderid, just return recipient id
    return recipientUid;
  };

  function createAndSendMessage() {
    if (!message) {
      showErrorToast('The message cannot be empty');
      return;
    }

    if (!userDoc) {
      router.push('/login');
      return;
    }
    // if user is not verified, redirect to verify page unless they are messaging support
    if (
      !user?.phoneNumber &&
      recipientUid !== process.env.NEXT_PUBLIC_SUPPORT_ID &&
      !userDoc?.roles?.support
    ) {
      router.push(`/verify-phone?redirect=${encodeURI(pathname ?? '/')}`);
      dismiss();
      return;
    }
    if (!message) return;
    // const redactedMessage = userDoc?.roles?.admin
    //   ? message
    //   : redactPhonesAndEmailsFromStr(message);
    const messageDoc = {
      uid: userDoc?.uid ?? '',
      content: message,
      created_at: Date.now(),
    } as ChatMessageDocument;
    // Two cases: chat exists or chat doesn't exist
    // If chat exists, just send the message
    const existingRegularChat = realtimeChats?.find(
      (chat) =>
        chat.uids?.includes(recipientUid) &&
        !productId &&
        !order &&
        !chat.is_offer &&
        !chat.product_id &&
        !chat.order_id
    );
    const existingProductChat = realtimeChats?.find(
      (chat) =>
        chat.product_id &&
        chat.product_id === productId &&
        recipientUid === chat.seller_id
    );
    const existingOrderChat = realtimeChats?.find(
      (chat) =>
        chat.order_id &&
        chat.order_id === order?.id &&
        chat.seller_id !== process.env.NEXT_PUBLIC_SUPPORT_ID &&
        chat.buyer_id !== process.env.NEXT_PUBLIC_SUPPORT_ID &&
        !chat.is_auto
    );

    const existingChat =
      existingRegularChat || existingProductChat || existingOrderChat;

    let chatToOpen = existingChat;
    if (existingChat) {
      sendMessageToChat(messageDoc, existingChat, userDoc?.uid, product);
    } else {
      // If chat doesn't exist, create the chat and with the message
      const getBuyerId = () => {
        // if productid, check if user is seller or buyer
        if (productId) {
          return product?.seller_id === userDoc?.uid
            ? recipientUid
            : userDoc?.uid;
        }
        // if orderid, check if user is seller or buyer
        if (order) {
          return order.buyer_id === userDoc?.uid ? userDoc?.uid : recipientUid;
        }
        // if no productid or orderid, just return user id
        return userDoc?.uid;
      };

      const chatDoc: Partial<ChatDocument> = {
        uids: [userDoc?.uid ?? '', recipientUid],
        buyer_id: getBuyerId() ?? '',
        seller_id: getSellerId() ?? '',
        ...(order && {
          order_id: order.id,
          order_num: order.order_num,
        }),
        messages: [messageDoc],
        product_id: productId ?? '',
        created_at: Date.now(),
        unread: {
          [recipientUid]: true,
          [userDoc?.uid ?? '']: false,
        },
        is_offer: false,
      };
      if (userDoc?.uid && userDoc.uid !== process.env.STAGING_ID) {
        chatDoc.unread = { [recipientUid]: true, [userDoc.uid]: false };
      }
      createChat(chatDoc as ChatInput);
      chatToOpen = chatDoc as ChatDocument;
    }
    setMessage('');
    dismiss();
    if (isMobile()) {
      router.push('/dashboard/messages');
    } else if (chatToOpen) {
      openChatDrawer(chatToOpen);
    }
  }

  function getRecipientPhone(recipient: UserDocument) {
    if (recipient.phone) {
      return recipient.phone;
    }
    if (recipient.addresses) {
      const address =
        recipient.addresses.find((address) => address.is_default) ??
        recipient.addresses[0];
      if (address && address.phone) {
        return address.phone;
      }
    }
    return 'No phone number found';
  }

  return (
    <BaseModal
      isOpen={isOpen}
      dismiss={dismiss}
      title="Send Message"
      isFullScreen={isMobile()}
    >
      <div className="flex w-full flex-col gap-[2.4rem] rounded-3xl p-8 lg:w-[36rem]">
        {recipient && (
          <div className="flex items-center gap-[1.6rem]">
            <SafeImage
              src={recipient?.photo}
              key={recipient?.photo}
              alt={'user image'}
              className="rounded-full"
              width={50}
              height={50}
            />
            <span className="text-[1.8rem] font-semibold text-brand-secondary">
              {recipient.username}
            </span>
            {recipient.is_verified && <ShieldCheckIcon />}
          </div>
        )}

        {product && (
          <div className="w-full pl-1">
            <ProductInfo product={product} />{' '}
          </div>
        )}
        {userDoc?.roles?.admin && data && (
          <div className="flex w-full flex-col gap-[0.8rem]">
            <span>Email: {data.email}</span>
            <span>Phone: {getRecipientPhone(data)}</span>
          </div>
        )}
        <div className="w-full">
          <FormTextarea
            placeholder="Send message to seller"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="flex w-full items-center justify-center gap-[1.6rem] font-semibold text-brand-secondary">
          <Button
            type="secondary"
            text="Send"
            leadingIcon={<SendIcon />}
            width="fluid"
            onClick={() => createAndSendMessage()}
          />
        </div>
      </div>
    </BaseModal>
  );
}

export default ChatModal;
