'use client';

import ConfirmationModal from '@c/modals/ConfirmationModal';
import MobileConfirmationModal from '@c/modals/MobileConfirmationModal';
import { isMobile } from '@util/index';
import { createContext, useContext, useState } from 'react';

interface ConfirmationModalProps {
  message: string;
  helpText?: string;
  onConfirm: () => void;
}

export const ConfirmationModalContext = createContext<{
  confirmationModal: (props: ConfirmationModalProps) => void;
}>({
  confirmationModal: () => {},
});

export const useConfirmationModal = () => useContext(ConfirmationModalContext);

export function ConfirmationModalProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [helpText, setHelpText] = useState('');
  const [onConfirmCallback, setOnConfirmCallback] = useState<
    (() => void) | null
  >(null);

  const confirmationModal = (props: ConfirmationModalProps) => {
    setModalTitle(props.message);
    setHelpText(props.helpText ?? '');
    setOnConfirmCallback(() => props.onConfirm);

    if (isMobile()) {
      setMobileOpen(true);
    } else {
      setIsOpen(true);
    }
  };

  const onClose = () => {
    setIsOpen(false);
    setOnConfirmCallback(null);
  };

  return (
    <ConfirmationModalContext.Provider value={{ confirmationModal }}>
      {children}

      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        title={modalTitle}
        helpText={helpText}
        onConfirm={() => {
          onConfirmCallback?.();
          onClose();
        }}
      />

      <MobileConfirmationModal
        title={modalTitle}
        helpText={helpText}
        show={mobileOpen}
        dismiss={() => setMobileOpen(false)}
        onConfirm={() => {
          onConfirmCallback?.();
          setMobileOpen(false);
        }}
      />
    </ConfirmationModalContext.Provider>
  );
}
