'use client';
import React, { useEffect, useState } from 'react';
import SafeImage from './SafeImage';
import { isBot } from 'next/dist/server/web/spec-extension/user-agent';
import { usePathname } from 'next/navigation';

const InstallAppBanner = () => {
  const [showInstallApp, setShowInstallApp] = useState(false);
  const pathname = usePathname();
  useEffect(() => {
    // only show user agent on chrome and mobile
    const showInstallApp =
      !isBot(navigator.userAgent) &&
      (navigator.userAgent.includes('Chrome') ||
        navigator.userAgent.includes('CriOS')) &&
      window.innerWidth < 768;
    setShowInstallApp(showInstallApp);
  }, []);

  if (
    !showInstallApp ||
    pathname?.startsWith('/app') ||
    pathname?.startsWith('/dashboard') ||
    pathname?.startsWith('/create-listing') ||
    pathname?.startsWith('/edit-listing') ||
    pathname?.startsWith('/checkout') ||
    pathname?.startsWith('/product')
  ) {
    return null;
  }
  return (
    <div
      className="fixed bottom-0 z-[100] flex h-[8rem] w-screen items-center gap-[1.6rem] bg-white px-[1.6rem] py-4"
      id="install-app-banner"
    >
      <div className="flex h-[4rem] w-[4rem] items-center justify-center rounded-lg bg-black p-1">
        <SafeImage
          src={'/icons/android-chrome-192x192.png'}
          height={192}
          width={192}
          alt="App Icon"
        />
      </div>
      <div className="flex flex-col">
        <h2 className="text-[1.6rem] font-bold">MX Locker</h2>
        <p className="text-[1.3rem]">The Powersports Marketplace</p>
      </div>
      <div className="grow"></div>
      <button
        className="whitespace-nowrap rounded-full bg-blue-800 px-4 py-2 text-[1.2rem] font-semibold text-white"
        onClick={() => {
          // if user agent includes CriOS, then it's chrome on iOS , send to app store
          if (navigator.userAgent.includes('CriOS')) {
            window.location.href =
              'https://apps.apple.com/us/app/mx-locker/id1543819006';
          } else if (navigator.userAgent.includes('Chrome')) {
            window.location.href =
              'https://play.google.com/store/search?q=mxlocker&c=apps';
          }
        }}
      >
        Get App
      </button>
    </div>
  );
};

export default InstallAppBanner;
