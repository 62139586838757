import Button from '@ui/Button';
import Carousel from '@ui/Carousel';
import { useAuth } from 'context/AuthContext';
import useMyFeed from '../hooks/useMyFeed';
import { renderProductCards } from '../utils';
import HomeSection from './Home/components/HomeSection';

const MyFeed = () => {
  const { data: myFeed, isLoading } = useMyFeed();
  const { userDoc } = useAuth();

  if (!userDoc) {
    return (
      <div className="flex h-[30rem] w-full items-center justify-center">
        <div className="flex flex-col items-center">
          <h1 className="text-[2rem] font-semibold">
            You&apos;re not signed in
          </h1>
          <p className="text-[1.6rem]">
            Please sign in to view gear in your size
          </p>

          <Button
            text="Sign In"
            href="/login"
            className="mt-4"
            type="secondary"
            radius={'xl'}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex w-full flex-col gap-y-8">
        {!!myFeed?.gear.length && (
          <HomeSection title="Gear In Your Size">
            <Carousel
              loading={isLoading}
              items={renderProductCards(
                myFeed?.gear
                  .sort((a, b) => (Math.random() - 0.5 > 0 ? 1 : -1))
                  .slice(0, 5),
                {
                  list_name: 'gear_for_you',
                  list_id: 'gear_for_you',
                },
                {
                  data_source: 'typesense',
                  page: 'homepage',
                  section: 'gear_for_you',
                }
              )}
            />
          </HomeSection>
        )}

        {myFeed?.parts.map(({ bike, products }) => {
          if (!bike) return null;
          if (!products.length) return null;

          return (
            <HomeSection
              key={JSON.stringify(bike)}
              title={`Parts for your ${bike.year} ${bike.make} ${bike.model}`}
            >
              <Carousel
                loading={isLoading}
                items={renderProductCards(
                  products,
                  {
                    list_name: 'parts_for_you',
                    list_id: 'parts_for_you',
                  },
                  {
                    data_source: 'typesense',
                    page: 'homepage',
                    section: 'parts_for_you',
                  }
                )}
              />
            </HomeSection>
          );
        })}
      </div>
    );
  }
};

export default MyFeed;
