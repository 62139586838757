'use client';
import { isMobile } from '@util/index';
import {
  CarouselContent,
  CarouselItem,
  CarouselNew,
  CarouselNext,
  CarouselPrevious,
} from './CarouselNew';
import ProductCardSkeleton from '@c/skeletons/ProductCardSkeleton';

interface CarouselProps {
  items: React.ReactNode[];
  basis?: string;
  loading?: boolean;
  emptyMessage?: React.ReactNode;
  incrementBy?: number;
  spacing?: number;
  slideClassName?: string;
}

const CarouselComponent = ({
  items,
  basis = 'basis-1/2 sm:basis-1/5',
  loading,
  emptyMessage,
  incrementBy,
  spacing,
  slideClassName,
}: CarouselProps) => {
  return (
    <CarouselNew
      opts={{
        slidesToScroll: incrementBy ?? isMobile() ? 2 : 5,
      }}
    >
      <CarouselContent className={spacing ? `-ml-${spacing}` : ''}>
        {!loading ? (
          items.length ? (
            items.map((item, index) => {
              return (
                <CarouselItem
                  className={`${basis} ${
                    spacing ? `ml-${spacing}` : ''
                  } ${slideClassName}`}
                  key={index}
                >
                  {item}
                </CarouselItem>
              );
            })
          ) : (
            <CarouselItem>{emptyMessage}</CarouselItem>
          )
        ) : (
          <>
            <CarouselItem className={basis} key={0}>
              <ProductCardSkeleton />
            </CarouselItem>
            <CarouselItem className={basis} key={1}>
              <ProductCardSkeleton />
            </CarouselItem>
            <CarouselItem className={basis} key={2}>
              <ProductCardSkeleton />
            </CarouselItem>
            <CarouselItem className={basis} key={3}>
              <ProductCardSkeleton />
            </CarouselItem>
            <CarouselItem className={basis} key={4}>
              <ProductCardSkeleton />
            </CarouselItem>
          </>
        )}
      </CarouselContent>
      <CarouselNext />
      <CarouselPrevious />
    </CarouselNew>
  );
};

export default CarouselComponent;
