import {
  AnotherPersonCartOverlay,
  DraftOverlay,
  SoldOverlay,
} from '@c/dashboard/pages/ListingsPage';
import { ChevronLeftIcon, ChevronRightIcon } from '@c/icons';
import { ProductDocument } from '@models/product';
import { useAuth } from 'context/AuthContext';
import { MotionButton, MotionDiv } from 'motion';
import { useRef, useState } from 'react';
import Hammer from 'react-hammerjs';
import SafeImage from './SafeImage';
import { HeartIcon } from '@heroicons/react/20/solid';
import {
  addProductToFavorites,
  removeProductFromFavorites,
} from '@util/firestore/products';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { UserDocument } from 'models/user';
import { useRouter } from 'next/navigation';
interface ProductCarouselProps {
  product: ProductDocument;
  openProductViewer: (index: number) => void;
  stack?: boolean;
}

// function that wraps around the index to keep it within the range of 0 to count
const wrap = (index: number, count: number) => {
  if (index < 0) return count - 1;
  if (index >= count) return 0;
  return index;
};

const Dots = ({ count, active }: { count: number; active: number }) => (
  <div className="absolute bottom-0 left-0 right-0 mb-8 flex justify-center gap-[1rem]">
    {new Array(count).fill(1).map((_el, i) => (
      <MotionDiv
        key={`slider-${i}`}
        className="h-[.8rem] w-[.8rem] rounded-full bg-brand-white"
        initial={false}
        animate={{
          scale: active === i ? 1.5 : 1,
          opacity: active === i ? 1 : 0.5,
        }}
      />
    ))}
  </div>
);

export const handleLikeClick = async (
  e: React.MouseEvent,
  userDoc: UserDocument,
  id: string,
  setIsLiked: (isLiked: boolean) => void,
  isLiked: boolean,
  queryClient: QueryClient
) => {
  e.stopPropagation();

  if (!userDoc) return;
  if (userDoc?.favorites.includes(id)) {
    await removeProductFromFavorites(userDoc.uid, id);
  } else {
    await addProductToFavorites(userDoc.uid, id);
  }

  setIsLiked(!isLiked);

  queryClient.invalidateQueries({
    queryKey: ['authUser'],
  });
};

const ImageSlider = ({
  images,
  title,
  video,
  id,
  favorite_count,
}: Pick<
  ProductDocument,
  'images' | 'title' | 'video' | 'id' | 'favorite_count'
>) => {
  const constraintsRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLiked, setIsLiked] = useState(false);

  const { userDoc } = useAuth();
  const queryClient = useQueryClient();
  const router = useRouter();

  return (
    <div
      className="relative aspect-square w-full overflow-hidden bg-brand-darker-white"
      ref={constraintsRef}
    >
      {favorite_count >= 1 && (
        <div className="absolute left-2 right-0 top-0 z-20 mt-2 flex justify-end gap-[1rem] sm:hidden">
          <AnotherPersonCartOverlay count={favorite_count} />
        </div>
      )}

      {/* mobile like button */}
      <div className="absolute bottom-0 right-8 z-20 mb-8 flex justify-end gap-[1rem]">
        <MotionButton
          className={`flex h-[5rem] w-[5rem] items-center justify-center rounded-full p-4 ${
            !userDoc?.favorites.includes(id) ? 'bg-white' : 'bg-brand-secondary'
          }`}
          type="button"
          onClick={(e) => {
            if (userDoc) {
              handleLikeClick(e, userDoc, id, setIsLiked, isLiked, queryClient);
            } else {
              router.push('/login');
            }
          }}
          whileTap={{ scale: 0.8 }}
          animate={{
            scale: [1, 1.2, 1],
            transition: {
              duration: 0.3,
              times: [0, 0.5, 1],
              ease: 'easeInOut',
            },
          }}
          aria-label="Like product"
        >
          <HeartIcon
            className={`h-full w-full ${
              !userDoc?.favorites.includes(id)
                ? 'stroke-black text-white'
                : 'text-white'
            }`}
            aria-label="Like product"
          />
        </MotionButton>
      </div>

      {/* @ts-ignore */}
      <Hammer
        onSwipe={(e) => {
          const container = document.getElementById('container');
          if (e.direction === 4) {
            if (activeIndex > 0) {
              container?.scrollTo({
                left: (activeIndex - 1) * window.innerWidth,
                behavior: 'smooth',
              });
              setActiveIndex(activeIndex - 1);
            }
          }
          if (e.direction === 2) {
            if (activeIndex < images.length - 1) {
              container?.scrollTo({
                left: (activeIndex + 1) * window.innerWidth,
                behavior: 'smooth',
              });
              setActiveIndex(activeIndex + 1);
            }
          }
        }}
      >
        <div
          className="absolute inset-0 flex max-w-[100vw] overflow-hidden duration-500"
          id="container"
        >
          {!!video && (
            <div className="relative h-full w-full flex-shrink-0 snap-start bg-brand-primary-lighter">
              <video
                autoPlay
                loop
                muted
                playsInline
                className="smx-auto object-cover"
                draggable={false}
                src={video.full}
              />
            </div>
          )}

          {images.map((image, i) => {
            return (
              <div
                key={`image-${i}-${image.thumb}`}
                className="relative h-full w-full flex-shrink-0 snap-start bg-brand-primary-lighter"
              >
                <SafeImage
                  alt={title}
                  src={image.thumb}
                  fallbackSrc={image.download_url}
                  className="mx-auto object-cover"
                  draggable={false}
                  fill
                  height={600}
                  width={600}
                  priority={i === 0}
                  fetchPriority={i === 0 ? 'high' : 'low'}
                />
              </div>
            );
          })}
        </div>
      </Hammer>

      <Dots count={images.length} active={activeIndex} />
    </div>
  );
};

const ProductCarousel = ({
  product,
  openProductViewer,
  stack,
}: ProductCarouselProps) => {
  const { userDoc } = useAuth();
  const [imageIndex, setImageIndex] = useState(product.video ? -1 : 0);
  const [isLiked, setIsLiked] = useState(false);
  const queryClient = useQueryClient();
  const router = useRouter();

  return (
    <>
      {/* mweb */}
      <div
        id="mobile-product-carousel"
        className="relative flex w-full sm:hidden"
        onClick={() => openProductViewer(imageIndex)}
      >
        <ImageSlider
          images={product.images}
          video={product.video}
          title={product.title}
          id={product.id}
          favorite_count={product.favorite_count}
        />

        {product.out_of_stock && !product.is_auction && (
          <SoldOverlay variant="sold" />
        )}
      </div>

      {/* dweb */}
      <div
        className={`hidden max-h-[57rem] w-full max-w-full overflow-hidden ${
          stack ? 'sm:flex sm:flex-col-reverse' : 'sm:flex'
        }`}
      >
        <div
          className={`flex ${
            stack
              ? 'z-30 max-w-[57rem] flex-row overflow-y-hidden overflow-x-scroll'
              : 'min-w-fit flex-col overflow-y-scroll'
          } gap-[1.2rem]`}
        >
          {product.video && (
            <div
              onClick={() => setImageIndex(-1)}
              className="relative aspect-square h-[8rem] min-w-[8rem] max-w-full cursor-pointer"
            >
              <video
                autoPlay
                loop
                muted
                playsInline
                className="mx-auto h-[8rem] object-cover"
                draggable={false}
                width={80}
                height={80}
                src={product.video.full}
              />
            </div>
          )}

          {product.images.map((image, i) => {
            const isSelected = i === imageIndex;

            return (
              <div
                key={`thumbnail-${i}`}
                className={`relative min-h-[8rem] min-w-[8rem] cursor-pointer rounded-[1rem] transition-opacity ${
                  isSelected ? 'opacity-100' : 'opacity-50'
                }`}
                onClick={() => setImageIndex(i)}
              >
                <SafeImage
                  src={image.thumb}
                  fallbackSrc={image.download_url}
                  fill
                  alt={product.title}
                  draggable={false}
                  className={`rounded-[1rem] border-2 ${
                    isSelected ? 'border-brand-black' : 'border-zinc-200'
                  } object-cover p-1`}
                />
              </div>
            );
          })}
        </div>

        <div className="flex h-fit w-full justify-center rounded-[2rem] bg-[#F2F2F2] sm:ml-4 sm:w-[80rem]">
          {/* desktop like button */}
          <div className="absolute bottom-0 right-8 z-20 mb-8 flex justify-end gap-[1rem]">
            <MotionButton
              className={`flex h-[5rem] w-[5rem] items-center justify-center rounded-full p-4 ${
                !userDoc?.favorites.includes(product.id)
                  ? 'bg-white'
                  : 'bg-brand-secondary'
              }`}
              type="button"
              onClick={(e) => {
                if (userDoc) {
                  handleLikeClick(
                    e,
                    userDoc,
                    product.id,
                    setIsLiked,
                    isLiked,
                    queryClient
                  );
                } else {
                  router.push('/login');
                }
              }}
              whileTap={{ scale: 0.8 }}
              animate={{
                scale: [1, 1.2, 1],
                transition: {
                  duration: 0.3,
                  times: [0, 0.5, 1],
                  ease: 'easeInOut',
                },
              }}
              aria-label="Like product"
            >
              <HeartIcon
                className={`h-full w-full ${
                  !userDoc?.favorites.includes(product.id)
                    ? 'stroke-black text-white'
                    : 'text-white'
                }`}
                aria-label="Like product"
              />
            </MotionButton>
          </div>

          <div
            onClick={() => openProductViewer(imageIndex)}
            className="relative aspect-square w-full cursor-zoom-in sm:w-[57rem]"
          >
            {imageIndex === -1 && product.video ? (
              <video
                autoPlay
                loop
                muted
                playsInline
                draggable={false}
                src={product.video.full}
              ></video>
            ) : (
              <SafeImage
                src={product.images[imageIndex]?.thumb}
                alt={product.title}
                fill
                width={1000}
                height={1000}
                className="object-cover"
                key={`image-${product.images[imageIndex]?.thumb}`}
                fallbackSrc={product.images[imageIndex]?.download_url}
                priority={imageIndex === 0}
                fetchPriority={imageIndex === 0 ? 'high' : 'low'}
              />
            )}

            {product.images.length > 1 && (
              <div className="absolute z-20 mt-auto flex h-[57rem] w-full items-center justify-between px-[2rem]">
                <button
                  type="button"
                  aria-label="previous"
                  onClick={(e) => {
                    e.stopPropagation();
                    setImageIndex(wrap(imageIndex - 1, product.images.length));
                  }}
                  className="flex h-[4rem] w-[4rem] items-center justify-center rounded-full bg-white text-black"
                >
                  <ChevronLeftIcon />
                </button>
                <button
                  type="button"
                  aria-label="next"
                  onClick={(e) => {
                    e.stopPropagation();
                    setImageIndex(wrap(imageIndex + 1, product.images.length));
                  }}
                  className="flex h-[4rem] w-[4rem] items-center justify-center rounded-full bg-white text-black"
                >
                  <ChevronRightIcon />
                </button>
              </div>
            )}

            {product.is_draft &&
            (product.seller_id === userDoc?.uid || !!userDoc?.roles?.admin) ? (
              <DraftOverlay variant="draft" />
            ) : product.start_time &&
              product.start_time > Date.now() &&
              product.date_added ? (
              <DraftOverlay variant="auction" />
            ) : product.is_auction &&
              product.end_time &&
              product.end_time < Date.now() ? (
              <SoldOverlay variant="auction" />
            ) : product.out_of_stock &&
              (!product.is_auction || !product.date_added) ? (
              <SoldOverlay variant="sold" />
            ) : null}

            {product.favorite_count >= 1 && (
              <AnotherPersonCartOverlay count={product.favorite_count} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCarousel;
