import { useQuery } from '@tanstack/react-query';
import SafeImage from '@ui/SafeImage';
import { getProductById } from '@util/firestore/products';
import { useOffers } from './useOffers';
import { formatCurrency } from '@util/index';
import Link from 'next/link';
import { getProductSlug } from '@util/urlHelpers';

export default function MobileMessageOffer({ offer_id }: { offer_id: string }) {
  const { offer } = useOffers(offer_id);

  const { data: product } = useQuery({
    queryKey: ['product', offer?.product_id],
    queryFn: () => getProductById(offer!.product_id),
    enabled: !!offer?.product_id,
  });

  if (!product) return null;

  const sizes = [offer?.variation?.letter, offer?.variation?.number]
    .filter(Boolean)
    .join('/');

  return (
    <Link
      href={getProductSlug(product)}
      className="flex h-fit w-full gap-[1.5rem] p-[1.5rem]"
    >
      <div className="relative h-[5.5rem] w-[5.5rem]">
        <SafeImage
          src={product.thumbnail}
          fill
          alt="product image"
          className="rounded-2xl"
        />
      </div>

      <div className="flex flex-col">
        <div className="flex w-full justify-between">
          <span
            className={`line-clamp-1 h-[2.875rem] ${
              offer?.variation && 'w-[30rem]'
            } text-[1.875rem] font-medium leading-[2.875rem]`}
          >
            {product.title}
          </span>

          {sizes && (
            <div className="whitespace-nowrap rounded-[1rem] bg-brand-lightest-gray px-4 py-2">
              <span className="text-[1.8rem] font-semibold">Size: {sizes}</span>
            </div>
          )}
        </div>

        <span className="text-[1.625rem] font-semibold text-[#7F7F7F]">
          {formatCurrency(product.price)}
        </span>
      </div>
    </Link>
  );
}
