import MXProductCard from '@c/cards/MXProductCard';
import ProductCardSkeleton from '@c/skeletons/ProductCardSkeleton';
import { Popover, PopoverPanel } from '@headlessui/react';
import { ChatDocument, ChatMessageDocument } from '@models/chat';
import { PublicUserDocument } from '@models/user';
import { useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import { deleteMessage } from '@util/firestore/messages';
import { getProductBySlug } from '@util/firestore/products';
import { getPublicUserDoc } from '@util/firestore/users';
import { formatCurrency, formatMessage, isMobile } from '@util/index';
import { useAuth } from 'context/AuthContext';
import { useToastContext } from 'context/ToastContext';
import { useState } from 'react';
import { getOffer, updateOffer } from '@util/firestore/offers/';
import { ChevronDownIcon } from 'lucide-react';
import { formatTimeRemaining } from './MessageOffer';
import { AnimatePresence } from 'framer-motion';
import { OfferDocument } from 'models/offer';
import OfferOperationModal from './OfferOperationModal';
import { useOffers } from './useOffers';
import { LogoWithoutText } from '@c/icons/logoWithoutText';
import Avatar from '@ui/Avatar';
import { useUser } from '@util/hooks/useUser';
import Link from 'next/link';
import { getPaymentMethods } from '@util/firestore/payments';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@c/sheet';
import { ChevronRightIcon } from '@c/icons';
import { PaymentDocument } from 'models/payment';
import { MotionDiv } from 'motion';

const IMG_SIZE = 500;

const MessageImage = ({
  message,
  img,
  fallbackImg,
  onClick,
}: {
  message: ChatMessageDocument;
  img: string;
  fallbackImg?: string;
  onClick: (src: string) => void;
  isInbound?: boolean;
}) => {
  if (message.mime_type?.includes('video') && message.content) {
    return (
      <video controls width={IMG_SIZE}>
        <source width={IMG_SIZE} src={message.content}></source>
      </video>
    );
  }
  return (
    <div className="relative h-[20rem] w-[20rem]">
      <SafeImage
        src={img}
        key={img}
        fill
        alt={'img'}
        fallbackSrc={fallbackImg}
        className="cursor-pointer object-cover"
        onClick={() => onClick(img)}
      />
    </div>
  );
};

interface MessageBubbleProps {
  chat: ChatDocument;
  realtimeMessages?: ChatMessageDocument[];
  chatMessage: ChatMessageDocument;
  fallbackImg?: string;
  onImageClick: (image: string) => void;
}

interface MessageProps {
  message: ChatMessageDocument;
  timestamp: number;
  isSupport?: boolean;
  user?: PublicUserDocument | null;
  fallbackImg?: string;
  onImageClick: (image: string) => void;
}

const MessageOutbound = ({
  message,
  timestamp,
  fallbackImg,
  onImageClick,
}: MessageProps) => {
  return (
    <div className="flex max-w-[80%] flex-col items-end gap-[1.6rem] justify-self-end font-medium">
      {!message.is_img ? (
        <div className="flex flex-col items-end gap-[0.4rem]">
          <div className="flex gap-[0.8rem]">
            {message.content.includes('https://mxlocker.com/product/') ? (
              <MessageProduct
                slug={
                  message.content.split('/')[
                    message.content.split('/').length - 1
                  ]
                }
              />
            ) : (
              <div className="flex flex-col self-end rounded-bl-[1.5rem] rounded-tl-[1.5rem] rounded-tr-[1.5rem] bg-brand-secondary p-[1.6rem] text-right text-[1.7rem] text-white">
                <p
                  className="message-content max-w-[80vw] truncate whitespace-pre-line text-[1.5rem]"
                  dangerouslySetInnerHTML={{
                    __html: formatMessage(message.content).html,
                  }}
                ></p>
              </div>
            )}
          </div>
          {!!timestamp && (
            <p className="mr-4 text-[1.5rem] text-brand-gray ">
              {new Date(timestamp).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </p>
          )}
        </div>
      ) : (
        <MessageImage
          message={message}
          img={message.thumbnail ?? message.content}
          fallbackImg={message.download_url ?? fallbackImg}
          onClick={onImageClick}
        />
      )}
    </div>
  );
};

const MessageProduct = ({ slug }: { slug: string }) => {
  const { data, isLoading } = useQuery({
    queryKey: ['product', slug],
    queryFn: () =>
      getProductBySlug({ slug: slug.split(' ')[0].replace('.', '') }),
    enabled: !!slug,
  });
  const product = data?.results;
  if (isLoading) return <ProductCardSkeleton />;
  if (!product) return null;
  return (
    <>
      <MXProductCard
        product={product}
        attribution={{
          page: 'chat',
          section: 'chat',
          data_source: 'firebase',
        }}
      />
    </>
  );
};

const MessageInbound = ({
  message,
  timestamp,
  isSupport,
  onImageClick,
}: MessageProps) => {
  const {
    data: { data: user },
  } = useUser(message.uid);
  return (
    <div className="flex max-w-[80%] items-end gap-[1.2rem] self-start py-[1.6rem] text-[1.6rem] font-medium">
      {isSupport ? (
        <div className="hidden h-min items-center justify-center rounded-full  text-brand-white sm:flex">
          <LogoWithoutText />
        </div>
      ) : (
        <Link
          className="hidden shrink-0 pb-[1.9rem] sm:block"
          href={`/profile/${user?.username_slug}`}
        >
          <Avatar size="extraSmall" user={user} />
        </Link>
      )}
      <div className="flex flex-col items-start gap-[1.6rem]">
        {!message.is_img ? (
          <div className="flex flex-col gap-[0.4rem]">
            {message.content.includes('https://mxlocker.com/product/') && (
              <MessageProduct
                slug={
                  message.content.split('/')[
                    message.content.split('/').length - 1
                  ]
                }
              />
            )}

            <div
              className={`flex flex-col gap-[0.2rem] rounded-bl-[1.5rem] rounded-br-[1.5rem] rounded-tr-[1.5rem] p-[1.2rem] text-left ${
                isSupport
                  ? 'bg-brand-lightest-gray text-brand-black'
                  : 'bg-brand-lightest-gray text-brand-black'
              }`}
            >
              <p
                className="message-content max-w-[80vw] truncate whitespace-pre-line text-[1.5rem]"
                dangerouslySetInnerHTML={{
                  __html: formatMessage(message.content).html,
                }}
              ></p>
            </div>

            {!!timestamp && (
              <p className="mr-4 self-start text-[1.5rem] text-brand-gray">
                {new Date(timestamp).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </p>
            )}
          </div>
        ) : (
          <MessageImage
            message={message}
            img={message.thumbnail ?? message.content}
            fallbackImg={message.download_url}
            onClick={onImageClick}
            isInbound
          />
        )}
      </div>
    </div>
  );
};

const getBadgeInfo = (offer: OfferDocument, isLastMessage: boolean = false) => {
  if (offer.is_counter && !isLastMessage) {
    return {
      bg: '#F1F1F1',
      foreground: '#444444',
      text: 'Countered',
    };
  }

  // pending:0, accepted:1, declined:2, expired:3
  if (offer.state === 3) {
    return {
      bg: '#F1F1F1',
      foreground: '#444444',
      text: 'Expired',
    };
  }

  switch (offer.state) {
    case 1:
      return {
        bg: '#1E7A1E1A',
        foreground: '#1E7A1E',
        text: 'Accepted',
      };
    case 2:
      return {
        bg: '#F9E8E8',
        foreground: '#C41719',
        text: 'Declined',
      };
    case 3:
      return {
        bg: '#F1F1F1',
        foreground: '#444444',
        text: 'Expired',
      };
    default:
      return {
        bg: '#F1F1F1',
        foreground: '#444444',
        text: 'Pending',
      };
  }
};
function MessageOfferDetails({
  message,
  messageUser,
  offer,
  isOldOffer,
}: {
  message: ChatMessageDocument;
  messageUser?: PublicUserDocument | null;
  offer: OfferDocument | null;
  isOldOffer: boolean;
}) {
  const [collapsed, setCollapsed] = useState(true);
  const [acceptModalVisible, setAcceptModalVisible] = useState(false);
  const [counterModalVisible, setCounterModalVisible] = useState(false);
  const [declineModalVisible, setDeclineModalVisible] = useState(false);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const { userDoc } = useAuth();

  const { product, getNetEarnings } = useOffers(offer?.id ?? '');
  const { data: buyerDocument } = useQuery({
    queryKey: ['publicUser', offer?.buyer_id],
    queryFn: () => getPublicUserDoc({ uid: offer!.buyer_id }),
    enabled: !!offer?.buyer_id,
  });
  const { data: sellerDocument } = useQuery({
    queryKey: ['publicUser', offer?.seller_id],
    queryFn: () => getPublicUserDoc({ uid: offer!.seller_id }),
    enabled: !!offer?.seller_id,
  });
  if (!offer || !userDoc) return null;

  const isSender = message.uid === userDoc.uid;

  const offerType = message.content.includes('Counter')
    ? 'Counter'
    : message.content.includes('Exclusive')
    ? 'Exclusive'
    : '';
  const badgeInfo = getBadgeInfo(offer, !isOldOffer);
  if (!offer) return null;
  return (
    <div className="w-[38rem] space-y-8 rounded-[1.5rem] border border-zinc-200 bg-white p-6 shadow-sm">
      <div className="flex items-center justify-between">
        {userDoc.username === messageUser?.username ? (
          <span className="text-[1.8rem] font-semibold text-zinc-800">
            {offerType} Offer to{' '}
            {buyerDocument?.uid === userDoc.uid
              ? sellerDocument?.username
              : buyerDocument?.username}
          </span>
        ) : (
          <span className="text-[1.8rem] font-semibold text-zinc-800">
            {offerType} Offer from {messageUser?.username}
          </span>
        )}

        {offer.state === 0 && !isSender && !isOldOffer && (
          <button
            type="button"
            className="border-none bg-inherit outline-none"
            onClick={() => {
              setDeclineModalVisible(true);
            }}
          >
            <span className="text-[1.6rem] font-bold text-brand-secondary">
              Decline
            </span>
          </button>
        )}
      </div>

      <div className="mt-8 flex items-center gap-x-6">
        {isOldOffer ? (
          <h2 className="text-[2.4rem] font-bold text-black">Countered</h2>
        ) : (
          <h2 className="text-[2.4rem] font-bold text-black">
            {formatCurrency(offer.price)}
          </h2>
        )}

        {!isOldOffer && (
          <button
            className="border-none bg-inherit outline-none"
            onClick={() => setCollapsed(!collapsed)}
          >
            <ChevronDownIcon
              className={`h-8 w-8 text-zinc-400 ${
                collapsed ? '' : 'rotate-180'
              }`}
            />
          </button>
        )}
      </div>

      <AnimatePresence initial={false}>
        {!collapsed && !isOldOffer && (
          <MotionDiv
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.1, ease: 'easeInOut' }}
          >
            <MotionDiv
              variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}
              transition={{ duration: 0.1 }}
              className="flex flex-col gap-y-4"
            >
              <div className="flex items-center gap-x-2">
                <span className="text-[1.8rem] font-medium text-zinc-500">
                  Offer Price:
                </span>
                <span className="text-[1.8rem] font-semibold text-zinc-600">
                  {formatCurrency(offer.price)}
                </span>
              </div>

              {!!offer.shipping_cost && (
                <div className="flex items-center gap-x-2">
                  <span className="text-[1.8rem] font-medium text-zinc-500">
                    Shipping:
                  </span>
                  <span className="text-[1.8rem] font-semibold text-zinc-600">
                    {formatCurrency(offer.shipping_cost)}
                  </span>
                </div>
              )}

              {!!offer.tax && (
                <div className="flex items-center gap-x-2">
                  <span className="text-[1.8rem] font-medium text-zinc-500">
                    Tax:
                  </span>
                  <span className="text-[1.8rem] font-semibold text-zinc-600">
                    {formatCurrency(offer.tax)}
                  </span>
                </div>
              )}

              <div className="flex items-center gap-x-2 border-b border-t border-zinc-300 py-4">
                <span className="text-[1.8rem] font-medium text-zinc-500">
                  Total:
                </span>
                <span className="text-[1.8rem] font-semibold text-zinc-600">
                  {formatCurrency(offer.total)}
                </span>
              </div>

              {product && offer.seller_id === userDoc?.uid && (
                <div className="flex items-center gap-x-2">
                  <span className="text-[1.8rem] font-bold text-brand-black">
                    Net earnings:
                  </span>
                  <span className="text-[1.8rem] font-bold text-brand-black">
                    {formatCurrency(getNetEarnings(offer))}
                  </span>
                </div>
              )}
            </MotionDiv>
          </MotionDiv>
        )}
      </AnimatePresence>

      <div className="flex flex-col gap-y-8">
        {offer.time_remaining &&
          offer.state !== 2 &&
          new Date(offer.time_remaining) > new Date() &&
          formatTimeRemaining(offer) !== '0d 0h' &&
          !isOldOffer && (
            <div className="flex items-center gap-x-2">
              <span className={'text-[1.5rem] font-medium text-zinc-700'}>
                Expires in {formatTimeRemaining(offer)}
              </span>
            </div>
          )}

        {offer.state === 0 && formatTimeRemaining(offer) !== '0d 0h' ? (
          <div className="flex w-full items-center gap-x-4">
            {!isSender && !offer.is_exclusive && !isOldOffer && (
              <button
                type="button"
                className="flex w-full items-center justify-center rounded-[1.5rem] border border-brand-black bg-inherit p-4 font-semibold text-brand-black"
                onClick={() => {
                  setCounterModalVisible(true);
                }}
              >
                Counter
              </button>
            )}

            {!isSender && !isOldOffer ? (
              <button
                type="button"
                className="flex w-full items-center justify-center rounded-[1.5rem] bg-[#1E7A1E] p-4 font-semibold text-white"
                onClick={() => {
                  setAcceptModalVisible(true);
                }}
              >
                Accept
              </button>
            ) : isOldOffer ? (
              <div className="flex w-full items-center justify-center rounded-[1.5rem] bg-[#F1F1F1] p-4 font-semibold text-[#444444]">
                Countered
              </div>
            ) : (
              <div className="flex w-full items-center justify-center rounded-[1.5rem] bg-[#F1F1F1] p-4 font-semibold text-[#444444]">
                Pending
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="flex w-full items-center gap-x-4">
              <div
                className="rounded-full px-6 py-2 font-semibold"
                style={{
                  backgroundColor: badgeInfo.bg,
                  color: badgeInfo.foreground,
                }}
              >
                {badgeInfo.text}
              </div>
            </div>
            {offer.state === 2 &&
              offer.failed &&
              offer.buyer_id === userDoc?.uid && (
                <UpdatePaymentOptions
                  offer_id={offer.id}
                  selectedPaymentMethod={offer.payment.id}
                />
              )}
          </>
        )}
      </div>

      <OfferOperationModal
        isOpen={acceptModalVisible}
        setIsOpen={setAcceptModalVisible}
        type="accept"
        onClose={() => setAcceptModalVisible(false)}
        offer={offer}
      />

      <OfferOperationModal
        isOpen={counterModalVisible}
        setIsOpen={setCounterModalVisible}
        type="counter"
        onClose={() => setCounterModalVisible(false)}
        offer={offer}
      />

      <OfferOperationModal
        isOpen={declineModalVisible}
        setIsOpen={setDeclineModalVisible}
        type="decline"
        onClose={() => setDeclineModalVisible(false)}
        offer={offer}
      />
    </div>
  );
}

function UpdatePaymentOptions({
  selectedPaymentMethod,
  offer_id,
}: {
  selectedPaymentMethod?: string;
  offer_id: string;
}) {
  const { data: paymentMethodData } = useQuery({
    queryKey: ['paymentMethods'],
    queryFn: getPaymentMethods,
  });
  const { showErrorToast, showSuccessToast } = useToastContext();
  async function updateOfferPayment(method: PaymentDocument) {
    if (method.id === selectedPaymentMethod) {
      showErrorToast('You already selected this payment method.');
      return;
    }
    try {
      await updateOffer(offer_id, {
        state: 0,
        failed: false,
        payment: {
          id: method.id,
          name: method.name,
          brand: method.brand,
          last4: method.last4,
          type: 0,
          saved: true,
        },
      });
      showSuccessToast('Payment method updated');
    } catch (e) {
      showErrorToast((e as Error).message);
    }
  }
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button type="tertiary" text="Update Payment Method" />
      </SheetTrigger>
      <SheetContent
        side={isMobile() ? 'bottom' : 'right'}
        className="z-[100] bg-white text-[1.8rem] text-black sm:min-w-[50rem]"
      >
        <SheetHeader>
          <SheetTitle>Update Payment Method</SheetTitle>
        </SheetHeader>
        <div className="flex w-full flex-col ">
          {paymentMethodData?.map((payment) => {
            return (
              <button
                type="button"
                className="mx-auto flex w-full items-center gap-4 border-b-[1px] border-gray-400 p-8"
                key={payment.id}
                onClick={() => updateOfferPayment(payment)}
              >
                <SafeImage
                  src={`/cc_brands/${payment.brand?.toLowerCase()}.png`}
                  alt={payment.brand ?? ''}
                  width={64}
                  height={48}
                  className="h-18 mr-2 w-28 object-contain"
                />
                <div className="flex flex-col items-start gap-2">
                  <div className="text-[2.2rem] font-semibold">
                    {payment.name}
                  </div>
                  <div>{payment.last4}</div>
                </div>
                <div className="flex  grow items-center justify-end gap-4 text-brand-mid-gray">
                  {selectedPaymentMethod === payment.id && (
                    <span>Selected</span>
                  )}
                  <ChevronRightIcon />
                </div>
              </button>
            );
          })}
        </div>
      </SheetContent>
    </Sheet>
  );
}

function MessageAuto({ message }: { message: ChatMessageDocument }) {
  return (
    <div className="my-12 flex w-full items-center justify-center">
      {message.content_html ? (
        <p
          className="message-content line-clamp-2 max-w-[80vw] truncate whitespace-pre-line text-center text-[1.6rem] font-medium text-zinc-500"
          // the auto messages will have 2 lines max.
          dangerouslySetInnerHTML={{
            __html: message.content_html?.replace(
              /<a class="router-link" data-link="([^"]+)">/g,
              '<a href="$1" class="text-brand-primary hover:underline">'
            ),
          }}
        ></p>
      ) : (
        <p className="message-content line-clamp-2 max-w-[80vw] truncate whitespace-pre-line text-center text-[1.6rem] font-medium text-zinc-500">
          {message.content}
        </p>
      )}
    </div>
  );
}

export default function MessageBubble({
  chat,
  realtimeMessages,
  chatMessage,
  fallbackImg,
  onImageClick,
}: MessageBubbleProps) {
  const { userDoc } = useAuth();
  const { showSuccessToast } = useToastContext();

  const { data: messageUser } = useQuery({
    queryKey: ['publicUser', chatMessage.uid],
    queryFn: () => getPublicUserDoc({ uid: chatMessage.uid }),
    enabled: !!chatMessage.uid,
  });

  const { data: offer } = useQuery({
    queryKey: ['offer', chat.offer_id],
    queryFn: () => getOffer(chat.offer_id!),
    enabled: !!chat.offer_id,
  });

  const { offer: realtimeOffer } = useOffers(chat.offer_id);
  const [menuOpen, setMenuOpen] = useState(false);

  if (!userDoc) return null;

  const onRightClick = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (userDoc?.roles?.support) {
      ev.preventDefault();
      ev.stopPropagation();
      setMenuOpen(true);
    }
  };

  const isOutbound = chatMessage.uid === userDoc?.uid;
  const isAuto = chatMessage.is_auto;
  const hideMessage =
    isAuto &&
    chatMessage.auto_recipient &&
    chatMessage.auto_recipient !== userDoc.uid;
  const isOfferMessage = chatMessage.content.startsWith('New Offer:');
  const isExclusiveOfferMessage = chatMessage.content.startsWith(
    'A seller sent you an exclusive offer'
  );
  if (
    chatMessage.content.startsWith(
      'The seller sent you and other interested buyers'
    ) &&
    chat.is_offer &&
    offer?.seller_id === userDoc?.uid
  )
    return null;

  const offerMessages = realtimeMessages?.filter(
    (m) =>
      m.offer_amount ||
      m.content.startsWith('New Offer: ') ||
      m.content.startsWith('A seller sent you an exclusive offer')
  );
  const currentOfferMessageIndex = offerMessages?.findIndex(
    (m) => m.created_at === chatMessage.created_at
  );

  if (isOfferMessage || isExclusiveOfferMessage) {
    return (
      <div
        className={`mb-8 flex w-fit flex-col ${
          isOutbound ? 'ml-auto pr-4' : 'mr-auto'
        }`}
      >
        <MessageOfferDetails
          message={chatMessage}
          messageUser={messageUser}
          offer={realtimeOffer}
          isOldOffer={
            (offerMessages?.length ?? 0) - 1 !== currentOfferMessageIndex
          }
        />

        {Boolean(chatMessage.created_at) && (
          <p
            className={`mt-4 text-[1.5rem] text-brand-gray ${
              isOutbound ? 'self-end' : 'self-start'
            }`}
          >
            {new Date(chatMessage.created_at).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </p>
        )}
      </div>
    );
  }

  return (
    <div onContextMenu={onRightClick} className="grid">
      {isAuto ? (
        <>{!hideMessage && <MessageAuto message={chatMessage} />}</>
      ) : (
        <>
          {isOutbound ? (
            <MessageOutbound
              message={chatMessage}
              timestamp={chatMessage.created_at ?? 0}
              fallbackImg={fallbackImg}
              user={messageUser}
              isSupport={userDoc.roles?.admin}
              onImageClick={onImageClick}
            />
          ) : (
            <MessageInbound
              message={chatMessage}
              timestamp={chatMessage.created_at ?? 0}
              isSupport={chatMessage.uid === process.env.NEXT_PUBLIC_SUPPORT_ID}
              user={messageUser}
              fallbackImg={fallbackImg}
              onImageClick={onImageClick}
            />
          )}
        </>
      )}

      {menuOpen && (
        <Popover className={isOutbound ? 'text-right' : ''}>
          <PopoverPanel static>
            <Button
              width="fluid"
              align={isOutbound ? 'right' : 'left'}
              type="text"
              text="Delete message"
              noXPadding
              onClick={() => {
                if (
                  window.confirm(
                    'Are you sure you want to delete this message?'
                  )
                ) {
                  deleteMessage(chat.id!, chatMessage);
                }
              }}
            />
            <Button
              width="fluid"
              align={isOutbound ? 'right' : 'left'}
              type="text"
              text="Copy message"
              noXPadding
              onClick={() => {
                navigator.clipboard.writeText(chatMessage.content);
                showSuccessToast(
                  'Message copied to clipboard',
                  'bottom-center'
                );
                setMenuOpen(false);
              }}
            />
            <Button
              width="fluid"
              align={isOutbound ? 'right' : 'left'}
              type="text"
              text="Cancel"
              noXPadding
              onClick={() => setMenuOpen(false)}
            />
          </PopoverPanel>
        </Popover>
      )}
    </div>
  );
}
