'use client';

import { ChevronDownIcon, ChevronRightIcon } from '@c/icons';
import { cva, VariantProps } from 'class-variance-authority';
import { AnimatePresence, motion } from 'framer-motion';
import { MotionDiv } from 'motion';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

const AccordionStyles = cva('transition-all cursor-pointer', {
  variants: {
    hover: {
      default: 'hover:text-brand-secondary ',
      text: 'hover:text-brand-secondary',
      'text-green': 'hover:text-brand-mid-green',
      none: '',
    },
    intent: {
      default: 'p-[0.8rem] ',
      faq: 'p-0 pb-[1.6rem]',
      fullWidth: 'p-0 py-[0.8rem]',
    },
  },
  defaultVariants: {
    hover: 'default',
    intent: 'default',
  },
});

interface AccordionProps extends VariantProps<typeof AccordionStyles> {
  title: string | React.ReactElement;
  children: React.ReactNode;
  startOpen?: boolean;
  divider?: boolean;
  highlightHeaderOnOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  noBoldTitle?: boolean;
  hideArrow?: boolean;
  titleHref?: string;
  arrowLeft?: boolean;
  arrowRight?: boolean;
  onTitleClick?: () => void;
  arrowStyles?: string;
  rightSlot?: React.ReactNode;
  locked?: boolean;
  mobileArrowRotation?: boolean;
  titleClassName?: string;
}
const Accordion = ({
  title,
  children,
  startOpen,
  divider,
  hover,
  intent,
  highlightHeaderOnOpen,
  onOpen,
  onClose,
  noBoldTitle,
  hideArrow,
  titleHref,
  arrowLeft,
  onTitleClick,
  arrowStyles,
  rightSlot,
  locked,
  arrowRight,
  mobileArrowRotation = false, // the rotation of the arrow on mobile must be 180 degrees to match the design
  titleClassName,
}: AccordionProps) => {
  const pathname = usePathname();
  const [open, setOpen] = useState(startOpen);
  const [shouldAnimate, setShouldAnimate] = useState(!startOpen);

  const getIconRotation = () => {
    return open
      ? mobileArrowRotation
        ? 'rotate-180'
        : 'rotate-90'
      : 'rotate-0';
  };

  const getHeaderColor = () => {
    return highlightHeaderOnOpen && open
      ? 'text-brand-secondary'
      : 'text-brand-black';
  };

  // Logic to animate the accordion after first render. This is needed because we don't want to animate when startOpen is true.
  useEffect(() => {
    if (open) {
      setShouldAnimate(true);
    }
  }, [open]);

  function handleClick() {
    if (locked) return;
    if (open) {
      onClose?.();
      setOpen(false);
    } else {
      onOpen?.();
      setOpen(true);
    }
  }

  return (
    <div className="w-full ">
      <div
        onClick={handleClick}
        className={`relative flex w-full items-center justify-between rounded-lg
        ${AccordionStyles({ hover, intent })}
        ${getHeaderColor()}
        ${!!arrowStyles ? arrowStyles : ''}
        `}
      >
        {!hideArrow && arrowLeft && (
          <div className={`${getIconRotation()} mr-4 transition-all`}>
            <ChevronDownIcon />
          </div>
        )}

        {titleHref ? (
          <Link
            href={titleHref}
            onClick={() => {
              onTitleClick?.();
            }}
            className={`w-full ${titleClassName} ${
              noBoldTitle ? '' : 'font-semibold'
            } ${
              pathname?.includes(titleHref)
                ? 'font-semibold text-brand-secondary underline'
                : ''
            }`}
          >
            {title}
          </Link>
        ) : onTitleClick ? (
          <button
            onClick={onTitleClick}
            className={`w-full text-left ${titleClassName} ${
              noBoldTitle ? '' : 'font-semibold'
            }`}
          >
            {title}
          </button>
        ) : (
          <span
            className={`w-full ${titleClassName} ${
              noBoldTitle ? '' : 'font-semibold'
            }`}
          >
            {title}
          </span>
        )}

        {!hideArrow && !arrowLeft && !arrowRight && (
          <div className={`${getIconRotation()} ml-4 transition-all`}>
            <ChevronRightIcon />
          </div>
        )}
        {!hideArrow && arrowRight && (
          <div className={`${getIconRotation()} ml-4 transition-all`}>
            <ChevronDownIcon />
          </div>
        )}
        {rightSlot}
      </div>
      <AnimatePresence>
        {!!divider && !open && (
          <motion.hr initial={{ opacity: 0 }} animate={{ opacity: 1 }} />
        )}
        {open && (
          <MotionDiv
            key="content"
            // animate in from top to bottom by growing height
            initial={{ height: 0, overflow: 'hidden' }}
            animate={{
              height: 'auto',
              overflow: 'hidden',
              transitionEnd: {
                overflow: 'visible',
              },
            }}
            // animate out from bottom to top by shrinking height
            exit={{ height: 0, overflow: 'hidden' }}
            transition={{ duration: shouldAnimate ? 0.25 : 0 }}
            className="w-full"
          >
            {children}
          </MotionDiv>
        )}
      </AnimatePresence>
      {/* render content for SEO / screen readers */}
      <span className="sr-only">{children}</span>
    </div>
  );
};

export default Accordion;
