import { cva, VariantProps } from 'class-variance-authority';
import { AnimatePresence } from 'framer-motion';
import { MotionDiv } from 'motion';
import { useEffect } from 'react';

interface DrawerProps extends VariantProps<typeof drawerStyles> {
  dismiss: () => void;
  show: boolean;
  children: React.ReactNode;
  side?: 'left' | 'right';
  fill?: boolean;
}

const drawerStyles = cva('fixed bg-brand-white h-screen overflow-clip', {
  variants: {
    side: {
      left: 'left-0',
      right: 'right-0',
    },
    top: {
      0: 'top-0',
      inherit: 'top-inherit',
    },
    fill: {
      true: 'w-full',
      false: 'w-full max-w-[80vw] sm:max-w-[50rem]',
    },
  },
  defaultVariants: {
    side: 'left',
    top: 0,
    fill: false,
  },
});

const BaseDrawer = ({
  show,
  dismiss,
  children,
  side,
  top,
  fill,
}: DrawerProps) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [show]);
  const topStyle = top === 0 ? 'top-0' : 'top-inherit';
  const xOffset = side === 'right' ? '+100%' : '-100%';
  return (
    <div className="fixed z-[60]">
      <AnimatePresence>
        {show && (
          <MotionDiv
            key="drawer"
            //   slide in from left
            initial={{ x: xOffset }}
            animate={{ x: 0 }}
            //   slide out to left
            exit={{ x: xOffset }}
            transition={{ duration: 0.3 }}
            className={`${drawerStyles({ side, top, fill })} z-10`}
          >
            {children}
          </MotionDiv>
        )}
      </AnimatePresence>
      {/* dimmer overlay */}
      <AnimatePresence>
        {show && (
          <MotionDiv
            className={`fixed left-0 top-0 h-full w-full bg-brand-black ${topStyle}`}
            onClick={() => {
              dismiss();
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default BaseDrawer;
