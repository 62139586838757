'use client';
import { DownloadIcon, HandIcon, PlusIcon } from '@c/icons';
import {
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react-dom-interactions';
import {
  ArrowDownRightIcon,
  EllipsisVerticalIcon,
  PencilIcon,
} from '@heroicons/react/20/solid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import Chip from '@ui/Chip';
import { Divider } from '@ui/Divider';
import { PaginatorClient } from '@ui/Paginator';
import SafeImage from '@ui/SafeImage';
import Select from '@ui/Select';
import Toggle from '@ui/Toggle';
import { getConditionOptions } from '@util/createListingHelpers';
import { exportListings } from '@util/firestore/listing';
import {
  bumpProduct,
  getProductViews,
  getSellerListingsPaginated,
  updateProductByKeys,
} from '@util/firestore/products';
import { searchClient } from '@util/getTypesense';
import useBrandOptions from '@util/hooks/useBrandOptions';
import useCountdown from '@util/hooks/useCountdown';
import useDebounce from '@util/hooks/useDebounce';
import useMainCats from '@util/hooks/useMainCats';
import { formatCurrency } from '@util/index';
import { logError } from '@util/logError';
import { getProductSlug } from '@util/urlHelpers';
import { useAuth } from 'context/AuthContext';
import { useToastContext } from 'context/ToastContext';
import { deleteField } from 'firebase/firestore';
import { MotionDiv } from 'motion';
import { Condition, ProductDocument, productSchema } from 'models/product';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { queryTypes, useQueryState } from 'nuqs';
import React, { useEffect, useState } from 'react';
import { FaEye, FaHeart } from 'react-icons/fa';
import { FiRefreshCw, FiTrendingUp } from 'react-icons/fi';
import { IoDocumentTextOutline, IoSearchOutline } from 'react-icons/io5';
import {
  Configure,
  Highlight,
  Index,
  InstantSearch,
  useHits,
  useSearchBox,
} from 'react-instantsearch-hooks-web';
import EmptyState from '../EmptyState';
import DeleteListingModal from './components/DeleteListingModal';
import OffersToLikersModal from './components/OffersToLikers';
import PriceDropModal from './components/PriceDropModal';
import SmartPricingModal from './components/SmartPricingModal';
import SmartPricingOffModal from './components/SmartPricingOffModal';

export const SoldOverlay = ({ variant }: { variant: 'auction' | 'sold' }) => (
  <div className="pointer-events-none absolute top-0 flex h-full w-full items-center justify-center @container">
    <div className=" flex w-full flex-col items-center justify-center bg-brand-secondary py-[0.8rem] font-semibold text-white opacity-80">
      {variant === 'auction' ? 'ENDED' : 'SOLD'}
    </div>
  </div>
);

export const DraftOverlay = ({ variant }: { variant: 'auction' | 'draft' }) => (
  <div className="absolute left-0 top-0 h-full w-full rounded-2xl bg-brand-lightest-black bg-opacity-50">
    <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center">
      <div
        className={`w-full ${
          variant === 'auction' ? 'bg-orange-500' : 'bg-yellow-500'
        } px-[1.6rem] py-[1.4rem] text-center font-semibold text-brand-white opacity-50`}
      >
        {variant === 'auction' ? 'Auction Starting Soon' : 'Draft'}
      </div>
    </div>
  </div>
);

export const AnotherPersonCartOverlay = ({ count }: { count: number }) => (
  <div className="absolute left-6 mt-6 h-[3rem] rounded-full bg-brand-secondary px-4">
    <div className="flex h-full items-center justify-center">
      <div className="whitespace-nowrap text-center text-[1.4rem] font-semibold uppercase text-brand-white sm:text-[1.5rem]">
        {count} {count === 1 ? 'person has' : 'people have'} this in their cart
      </div>
    </div>
  </div>
);

const ListingCardList = ({
  product,
  setSmartPricingModalOpen,
  setSelectedProduct,
  setDeleteListingModalOpen,
  setPriceDropModalOpen,
  setSmartPricingOffModalOpen,
  setOffersToLikersModalOpen,
}: {
  product: ProductDocument;
  smartPricingModalOpen: boolean;
  setSmartPricingModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedProduct: React.Dispatch<React.SetStateAction<ProductDocument>>;
  setDeleteListingModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPriceDropModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSmartPricingOffModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOffersToLikersModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isStarted = product.start_time! < Date.now();
  const timeRemaining = useCountdown(
    product.start_time!,
    product.end_time!,
    isStarted ? 'end' : 'start'
  );

  const { data: views } = useQuery({
    queryKey: ['views', product.id],
    queryFn: () => getProductViews(product.id),
    enabled: !!product.id,
  });

  const queryClient = useQueryClient();
  const { showSuccessToast } = useToastContext();

  const handleSmartPricing = () => {
    setSelectedProduct(product);

    if (product.floor_price) {
      setSmartPricingOffModalOpen(true);
    } else {
      setSmartPricingModalOpen(true);
    }
  };

  const handlePriceDrop = () => {
    setPriceDropModalOpen(true);
    setSelectedProduct(product);
  };

  const handleSendOffers = () => {
    setSelectedProduct(product);
    setOffersToLikersModalOpen(true);
  };

  const handleDeleteListing = () => {
    setDeleteListingModalOpen(true);
    setSelectedProduct(product);
  };

  const [actionsDropdownOpen, setActionsDropdownOpen] = React.useState(false);

  return (
    <div className="isolate flex h-full w-full flex-col gap-[1.2rem] rounded-2xl border border-b-[1px] border-zinc-100 shadow-lg lg:flex-row lg:items-center">
      <div className="flex w-full flex-row gap-[1.2rem]">
        <Link
          href={getProductSlug(product)}
          className="relative h-[20rem] min-w-[12rem] rounded-2xl lg:min-w-[12rem]"
        >
          <SafeImage
            className="h-[12rem] w-[12rem] rounded-bl-2xl rounded-br-2xl rounded-tl-2xl rounded-tr-2xl object-cover lg:h-[24.5rem] lg:w-[30rem] lg:rounded-br-none lg:rounded-tr-none"
            width={1920}
            height={1080}
            alt={'product image for ' + product.title}
            key={product.thumbnail}
            src={product.thumbnail}
          />
          {product.out_of_stock && !product.is_draft && !product.is_auction && (
            <SoldOverlay variant={'sold'} />
          )}
          {product.is_draft && <DraftOverlay variant="draft" />}
        </Link>

        <div className="flex h-full w-full flex-col justify-between gap-[0.4rem]">
          <div className="flex h-[24rem] w-full flex-col justify-between gap-[1.6rem] pl-2">
            <div className="flex h-full w-full flex-col justify-between pb-4">
              <div className="flex gap-[0.4rem]">
                {product.is_auction && (
                  <div className="w-fit">
                    <Chip text={'Auction'} color={'primary'} />
                  </div>
                )}
                {product.is_auction &&
                  product.end_time! < Date.now() &&
                  !product.is_draft &&
                  product.stock === 0 && (
                    <div className="w-fit">
                      <Chip
                        text={`Sold for ${formatCurrency(product.price)}`}
                        color={'success'}
                      />
                    </div>
                  )}
                {product.is_auction &&
                  product.end_time! < Date.now() &&
                  !product.is_draft &&
                  product.stock > 0 && (
                    <div className="w-fit">
                      <Chip text={`Didn't sell`} color={'error'} />
                    </div>
                  )}
              </div>

              <div className="flex w-full items-center lg:justify-between">
                <div className="grid grid-cols-2 items-center justify-between gap-x-6 gap-y-2 lg:flex lg:justify-between">
                  <span className="text-[1.5rem] text-gray-400">
                    {product.category}
                  </span>

                  <div className="flex items-center gap-x-4">
                    {!!product.size && product.size.letter && (
                      <span className="text-[1.5rem] text-gray-400">
                        Size: {product.size?.letter}
                      </span>
                    )}

                    {!!product.stock && (
                      <span className="text-[1.5rem] text-gray-400">
                        Stock: {product.stock}
                      </span>
                    )}
                  </div>

                  <div className="flex items-center gap-x-2">
                    <div className="flex items-center gap-x-6 pl-2">
                      {views?.views && (
                        <span className="flex items-center gap-[0.8rem]">
                          {/* why +1? So that the user sees the view they just added without needed to refresh from the db */}
                          <FaEye className="text-zinc-600" /> {views.views + 1}
                        </span>
                      )}

                      <span className="flex items-center gap-[0.8rem]">
                        <FaHeart className="text-zinc-600" />{' '}
                        {product.favorite_count}
                      </span>
                    </div>
                  </div>
                </div>

                {!product.integration_info && !product.out_of_stock && (
                  <div className="-mr-4 mt-4 hidden items-center gap-x-6 lg:flex">
                    <span className="text-[1.5rem] font-semibold text-black">
                      Smart Pricing
                    </span>

                    <div className="flex items-center gap-x-1">
                      <span
                        className={`pr-2 text-[1.5rem] font-bold ${
                          !!product.floor_price
                            ? 'text-brand-secondary'
                            : 'text-black'
                        }`}
                      >
                        {!!product.floor_price ? 'ON' : 'OFF'}
                      </span>

                      <Toggle
                        enabled={!!product.floor_price ? true : false}
                        onChange={handleSmartPricing}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-col">
                <Link
                  href={getProductSlug(product)}
                  className="line-clamp-3 w-fit text-[2rem] font-semibold leading-[2.7rem] text-brand-lightest-black lg:w-[40rem]"
                >
                  {/* @ts-ignore This works since it can either be a hit from typesense or a regular product. Could use some clean up to avoid the ts-ignore. */}
                  {product.__position ? (
                    // @ts-ignore
                    <Highlight attribute="title" hit={product} />
                  ) : (
                    product.title
                  )}
                </Link>

                {!!product.sku && (
                  <span className="text-2xl text-black">
                    SKU: {product.sku}
                  </span>
                )}
              </div>

              {product.is_auction &&
                product.end_time! > Date.now() &&
                !product.is_draft && (
                  <div className="flex  w-full items-center justify-center gap-[0.8rem] rounded-b-2xl p-2">
                    <span className="w-full font-medium text-brand-green">
                      {isStarted ? 'Ends in ' : 'Starts in '}
                      {timeRemaining.days > 0 && `${timeRemaining.days}d `}
                      {timeRemaining.hours}h {timeRemaining.minutes}m{' '}
                      {timeRemaining.seconds}s
                    </span>
                  </div>
                )}

              <div className="flex flex-col items-start gap-x-4 gap-y-4 lg:flex-row lg:items-center">
                <div className="isolate flex items-center gap-[1.2rem] font-bold">
                  {product.on_sale ? (
                    <div className="flex items-center gap-[0.8rem]">
                      <p
                        className={`text-[2.4rem] font-semibold leading-[2.4rem] ${
                          product.on_sale
                            ? 'font-bold text-brand-secondary'
                            : 'text-gray-400'
                        }`}
                      >
                        {formatCurrency(product.price)}
                      </p>
                      <p className="text-[2.4rem] font-semibold leading-[2.4rem] text-gray-400 line-through">
                        {formatCurrency(product.og_price)}
                      </p>
                    </div>
                  ) : (
                    <p className="text-h3 leading-[2.4rem]">
                      {formatCurrency(product.price)}
                    </p>
                  )}
                </div>

                {!!product.floor_price && (
                  <span className="rounded-2xl bg-red-50 px-4 py-2 text-red-500">
                    Floor Price: {formatCurrency(product.floor_price)}
                  </span>
                )}
                {!!product.purchase_entry_price && (
                  <span className="rounded-2xl bg-red-50 px-4 py-2 text-blue-500">
                    Purchase Entry Price:{' '}
                    {formatCurrency(product.purchase_entry_price)}
                  </span>
                )}
              </div>

              <div
                className={`-ml-48 flex w-fit justify-start gap-x-6 lg:ml-0 lg:justify-end`}
              >
                {!product.integration_info && (
                  <div className="flex items-center gap-x-2">
                    <Button
                      radius={'xl'}
                      text="Price Drop"
                      onClick={handlePriceDrop}
                      disabled={product.out_of_stock}
                      leadingIcon={<ArrowDownRightIcon className={'h-8 w-8'} />}
                      height="smaller"
                      width="small"
                    />
                  </div>
                )}
                {product.category !== 'Dirt Bikes' && (
                  <div className="flex items-center gap-x-2">
                    <Button
                      radius={'xl'}
                      onClick={handleSendOffers}
                      leadingIcon={<HandIcon width={16} height={16} />}
                      text="Send Offers"
                      height="smaller"
                      width="small"
                      disabled={
                        product.favorite_count === 0 || product.out_of_stock
                      }
                    />
                  </div>
                )}

                {!product.integration_info && !product.out_of_stock && (
                  <Link
                    href={`/edit-listing/${product.id}`}
                    className="hidden lg:flex"
                  >
                    <div className="flex items-center gap-x-2 rounded-xl bg-brand-lightest-gray px-4 py-3 text-[1.5rem] font-semibold text-black">
                      <PencilIcon className={'h-8 w-8'} /> Edit
                    </div>
                  </Link>
                )}

                <ActionsDropdown
                  open={actionsDropdownOpen}
                  setOpen={setActionsDropdownOpen}
                  product={product}
                  handleDeleteListing={handleDeleteListing}
                />
              </div>
            </div>
          </div>

          <div className="-ml-24 flex w-full items-center gap-x-4">
            {!product.integration_info && !product.out_of_stock && (
              <Link
                href={`/edit-listing/${product.id}`}
                className="flex lg:hidden"
              >
                <div className="flex items-center gap-x-2 rounded-xl bg-zinc-200 px-4 py-3 text-[1.5rem] font-semibold text-black">
                  <PencilIcon className={'h-8 w-8'} /> Edit
                </div>
              </Link>
            )}

            {!product.integration_info && !product.out_of_stock && (
              <div className="flex items-center gap-x-6 lg:hidden">
                <span className="text-[1.5rem] font-semibold text-black">
                  Smart Pricing
                </span>

                <div className="flex items-center gap-x-1">
                  <span
                    className={`pr-2 text-[1.5rem] font-bold ${
                      !!product.floor_price
                        ? 'text-brand-secondary'
                        : 'text-black'
                    }`}
                  >
                    {!!product.floor_price ? 'ON' : 'OFF'}
                  </span>

                  <Toggle
                    enabled={!!product.floor_price}
                    onChange={handleSmartPricing}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="w-full">
          {product.category === 'Dirt Bikes' && product.bumps > 0 && (
            <Button
              onClick={async () => {
                await bumpProduct(product.id);
                queryClient.invalidateQueries({
                  queryKey: ['listings'],
                });
                showSuccessToast('Successfully bumped listing!');
              }}
              width="fixed"
              text="Bump"
              leadingIcon={<FiTrendingUp width={24} height={24} />}
            />
          )}
        </div>
      </div>
      <hr />
    </div>
  );
};

interface ProductListProps {
  view?: 'grid' | 'list';
  products: ProductDocument[];
  smartPricingModalOpen: boolean;
  setSelectedProduct: any;
  setSmartPricingModalOpen: any;
  setDeleteListingModalOpen: any;
  setPriceDropModalOpen: any;
  setSmartPricingOffModalOpen: any;
  setOffersToLikersModalOpen: any;
}

// view, products
const ProductList = ({
  products,
  smartPricingModalOpen,
  setSelectedProduct,
  setSmartPricingModalOpen,
  setDeleteListingModalOpen,
  setPriceDropModalOpen,
  setSmartPricingOffModalOpen,
  setOffersToLikersModalOpen,
}: ProductListProps) => {
  return (
    <div className="flex w-full flex-col gap-[2rem] p-[1.6rem]">
      <div className="flex flex-col gap-[1.6rem]  ">
        {products.map((product) => (
          <ListingCardList
            key={product.id}
            setSmartPricingModalOpen={setSmartPricingModalOpen}
            product={product}
            smartPricingModalOpen={smartPricingModalOpen}
            setSelectedProduct={setSelectedProduct}
            setDeleteListingModalOpen={setDeleteListingModalOpen}
            setPriceDropModalOpen={setPriceDropModalOpen}
            setSmartPricingOffModalOpen={setSmartPricingOffModalOpen}
            setOffersToLikersModalOpen={setOffersToLikersModalOpen}
          />
        ))}
      </div>
    </div>
  );
};

type ActionsDropdownProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  product: ProductDocument;
  handleDeleteListing: () => void;
};

const ActionsDropdown = ({
  open,
  setOpen,
  product,
  handleDeleteListing,
}: ActionsDropdownProps) => {
  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: 'top-start',
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, { toggle: true }),
    // @ts-ignore
    useDismiss(context, { referencePointerDown: true }),
  ]);

  return (
    <>
      <button
        className="flex items-center justify-center rounded-lg bg-brand-lightest-gray px-4 py-2 text-black hover:bg-brand-lighter-gray"
        ref={reference}
        {...getReferenceProps()}
      >
        <EllipsisVerticalIcon className="h-8 w-8" />
      </button>

      {open && (
        <MotionDiv
          ref={floating}
          {...getFloatingProps()}
          initial={{ opacity: 0, scale: 0.1 }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          transition={{ duration: 0.2 }}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: 'max-content',
            transformOrigin: 'bottom left',
            zIndex: 100,
          }}
          className="flex flex-col items-start gap-x-4 gap-y-1 rounded-xl bg-white py-[1.6rem] shadow-lg"
        >
          <Link
            className={'w-full rounded-xl px-4 py-2'}
            href={{
              pathname: '/create-listing/',
              query: { id: product.id, step: 3 },
            }}
          >
            Duplicate
          </Link>

          <button
            className={
              'w-full border-t border-brand-lighter-gray px-4 py-3 font-semibold text-red-600'
            }
            onClick={handleDeleteListing}
          >
            Delete
          </button>
        </MotionDiv>
      )}
    </>
  );
};

export type Availability =
  | 'onSale'
  | 'inStock'
  | 'draft'
  | 'sold'
  | 'notSold'
  | 'paymentFailed'
  | 'all';

interface Filters {
  category: string | 'all';
  category1: string | 'all';
  condition: Condition | 'all';
  availability: Availability;
  brand: string | 'all';
  auctionName?: string | 'all';
}

interface ListingFiltersProps {
  view: 'grid' | 'list';
  setView: (view: 'grid' | 'list') => void;
  filters: Filters;
  setCategory: (category: string) => void;
  setCategory1: (category: string) => void;
  setCondition: (condition: Condition | 'all') => void;
  setAvailability: (availability: Availability) => void;
  setBrand: (brand: string) => void;
  setAuction: (auction: string) => void;
  limit: number;
  setLimit: (limit: number) => void;
}

const ListingFilters = ({
  filters,
  setCategory,
  setCategory1,
  setCondition,
  setAvailability,
  setBrand,
  setAuction,
  limit,
  setLimit,
}: ListingFiltersProps) => {
  const { userDoc } = useAuth();
  const mainCats = useMainCats();

  const { brandOptions } = useBrandOptions();
  return (
    <div>
      <div className="flex w-full flex-col justify-center gap-[1.6rem] p-[1.6rem] lg:flex-row lg:items-center">
        <h2 className="flex items-center gap-x-2 font-semibold text-brand-lightest-black">
          Filter
        </h2>

        <div className="grid grid-cols-2 gap-4 lg:flex">
          <Select
            options={[
              { label: 'All Category', value: 'all', id: 'all' },
            ].concat(
              mainCats.map((category) => ({
                label: category.name,
                value: category.name,
                id: category.name,
              }))
            )}
            allowEmpty={false}
            placeholder="All Category"
            value={filters.category}
            onChange={(option) => {
              setCategory(option.id);
            }}
            disabled={
              filters.availability === 'notSold' ||
              filters.availability === 'paymentFailed'
            }
          />

          {/* {filters.category && (
            <Select
              options={[
                { label: 'All Subcategories', value: 'all', id: 'all' },
              ].concat(
                Array.from(
                  catOptions?.allCats?.get(filters.category) ?? []
                ).map((category) => ({
                  label: category[0],
                  value: category[0],
                  id: category[0],
                }))
              )}
              allowEmpty={false}
              placeholder="All Subcategories"
              value={filters.category1}
              onChange={(option) => {
                setCategory1(option.id);
              }}
              disabled={
                filters.availability === 'notSold' ||
                filters.availability === 'paymentFailed'
              }
            />
          )} */}
          <Select
            options={[{ label: 'All Brands', value: 'all', id: 'all' }].concat(
              brandOptions
            )}
            allowEmpty={false}
            placeholder="All Brands"
            value={
              brandOptions.find((option) => option.value === filters.brand)
                ?.id ?? 'all'
            }
            onChange={(option) => {
              setBrand(option.value);
            }}
            disabled={
              filters.availability === 'notSold' ||
              filters.availability === 'paymentFailed'
            }
          />
          <div className="flex w-full gap-[1.6rem]">
            <Select
              options={[
                { label: 'All Condition', value: 'all', id: 'all' },
              ].concat(getConditionOptions())}
              allowEmpty={false}
              placeholder="All Condition"
              value={filters.condition}
              onChange={(option) => {
                setCondition(option.id as Condition);
              }}
              disabled={
                filters.availability === 'notSold' ||
                filters.availability === 'paymentFailed'
              }
            />
          </div>
          <div className="flex w-full gap-[1.6rem]">
            <Select
              options={[
                { label: 'All Availability', value: 'all', id: 'all' },
                { label: 'On Sale', value: 'onSale', id: 'onSale' },
                { label: 'Draft', value: 'draft', id: 'draft' },
                { label: 'In Stock', value: 'inStock', id: 'inStock' },
                { label: 'Sold', value: 'sold', id: 'sold' },
                { label: 'Not Sold', value: 'notSold', id: 'notSold' },
                {
                  label: 'Payment Failed',
                  value: 'paymentFailed',
                  id: 'paymentFailed',
                },
              ]}
              allowEmpty={false}
              placeholder="All Availability"
              value={filters.availability}
              onChange={(option) => {
                setAvailability(option.id as Availability);
              }}
            />
          </div>
          <div className="flex w-full gap-[1.6rem]">
            <Select
              options={[12, 24, 48, 96].map((option) => ({
                label: option.toString(),
                value: option.toString(),
                id: option.toString(),
              }))}
              allowEmpty={false}
              placeholder="Limit"
              value={limit.toString()}
              onChange={(option) => {
                setLimit(+option.value);
              }}
              disabled={
                filters.availability === 'notSold' ||
                filters.availability === 'paymentFailed'
              }
            />
          </div>
        </div>
      </div>
      {userDoc?.roles?.auctions && (
        <div className="flex w-min gap-[1.6rem] pl-5">
          <span className="flex items-center whitespace-nowrap font-semibold">
            Auction Name:{' '}
          </span>
          <Select
            options={[
              {
                label: 'No Auction Filter',
                value: 'all',
                id: 'all',
              },
              {
                label: 'Black Friday 2023',
                value: 'Black Friday 2023',
                id: 'Black Friday 2023',
              },
              {
                label: 'Brian Barnes Washougal 2023',
                value: 'Brian Barnes Washougal 2023',
                id: 'Brian Barnes Washougal 2023',
              },
              {
                label: 'Brian Barnes Ironman',
                value: 'Brian Barnes Ironman',
                id: 'Brian Barnes Ironman',
              },
              {
                label: 'Veteran MX 2024 Auction',
                value: 'Veteran MX 2024 Auction',
                id: 'Veteran MX 2024 Auction',
              },
              {
                label: 'Brian Barnes 2024',
                value: 'Brian Barnes 2024',
                id: 'Brian Barnes 2024',
              },
              {
                label: 'Matt Buyten',
                value: 'Matt Buyten',
                id: 'Matt Buyten',
              },
            ]}
            allowEmpty={false}
            placeholder="No Auction Filter"
            value={filters.auctionName}
            onChange={(option) => {
              setAuction(option.id);
            }}
          />
        </div>
      )}
    </div>
  );
};

export const ListingsPage = () => {
  const { userDoc } = useAuth();

  const queryClient = useQueryClient();
  const searchParams = useSearchParams();

  const [view, setView] = useState<'list' | 'grid'>('list');

  const [search, setSearch] = useState(searchParams?.get('search') ?? '');
  const debouncedSearchTerm = useDebounce(search, 500);

  const [smartPricingModalOpen, setSmartPricingModalOpen] = useState(false);
  const [smartPricingOffModalOpen, setSmartPricingOffModalOpen] =
    useState(false);

  const [deleteListingModalOpen, setDeleteListingModalOpen] = useState(false);
  const [priceDropModalOpen, setPriceDropModalOpen] = useState(false);
  const [offersToLikersModalOpen, setOffersToLikersModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductDocument>();
  const { showSuccessToast, showErrorToast } = useToastContext();

  const [page, setPage] = useQueryState('page', {
    history: 'push',
    ...queryTypes.integer.withDefault(1),
  });
  const [category, setCategory] = useQueryState('category', {
    history: 'push',
    ...queryTypes.string.withDefault('all'),
  });
  const [category1, setCategory1] = useQueryState('category1', {
    history: 'push',
    ...queryTypes.string.withDefault('all'),
  });
  const [brand, setBrand] = useQueryState('brand', {
    history: 'push',
    ...queryTypes.string.withDefault('all'),
  });
  const [condition, setCondition] = useQueryState('condition', {
    history: 'push',
    ...queryTypes.string.withDefault('all'),
  });
  const [availability, setAvailability] = useQueryState('availability', {
    history: 'push',
    ...queryTypes.string.withDefault('all'),
  });
  const [auction, setAuction] = useQueryState('auction', {
    history: 'push',
    ...queryTypes.string.withDefault('all'),
  });
  const [limit, setLimit] = useQueryState('limit', {
    history: 'push',
    ...queryTypes.integer.withDefault(12),
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      'listings',
      page,
      category,
      category1,
      brand,
      condition,
      availability,
      userDoc?.uid,
      limit,
      auction,
    ],
    queryFn: () =>
      getSellerListingsPaginated(
        userDoc?.uid ?? '',
        userDoc?.roles?.admin ?? false,
        page,
        category,
        category1,
        brand,
        condition as Condition,
        availability as Availability,
        auction,
        limit
      ),
  });

  function getFilters() {
    let filterString = '';
    if (!(userDoc?.roles?.admin || userDoc?.roles?.support)) {
      filterString += `seller_id:${userDoc?.uid} && `;
    }
    if (category !== 'all') {
      filterString += `category:${category} && `;
    }
    if (category1 !== 'all') {
      filterString += `category1:${category1} && `;
    }
    if (brand !== 'all') {
      filterString += `brand:${brand} && `;
    }
    if (condition !== 'all') {
      // if its new , filter by new, else filter by not new
      if (condition === 'New') {
        filterString += `condition:"${condition}" && `;
      } else {
        filterString += `condition:!="New" && `;
      }
    }
    if (availability === 'onSale') {
      filterString += `on_sale:true && `;
    }
    if (filterString.endsWith('&& ')) {
      filterString = filterString.slice(0, -4);
    }

    return filterString;
  }

  const products = data?.results ?? [];
  const index = 'products_alias';

  const updateFloorPrice = async (
    floorPrice: number | undefined,
    product: ProductDocument
  ) => {
    try {
      const keys = productSchema.pick({
        floor_price: true,
      });
      if (floorPrice) {
        const res = keys.safeParse({ floor_price: floorPrice });
        if (!res.success) {
          logError(res.error.message);
          throw new Error('Unable to update product. Please contact support.');
        }
      }
      const updates = {
        floor_price: floorPrice ? floorPrice : deleteField(),
        date_updated: Date.now(),
      };
      await updateProductByKeys(product.id, updates);
      setSmartPricingModalOpen(false);
      setSmartPricingOffModalOpen(false);

      queryClient.invalidateQueries({
        queryKey: ['listings'],
      });
      showSuccessToast('Floor price updated');
    } catch (e) {
      showErrorToast((e as Error).message);
    }
  };

  return (
    <div className="flex flex-col ">
      <div className="z-50">
        {!!selectedProduct && (
          <>
            <SmartPricingModal
              open={smartPricingModalOpen}
              setOpen={setSmartPricingModalOpen}
              product={selectedProduct}
              updateFloorPrice={updateFloorPrice}
              key={selectedProduct.id}
            />

            <SmartPricingOffModal
              open={smartPricingOffModalOpen}
              setOpen={setSmartPricingOffModalOpen}
              product={selectedProduct}
              updateFloorPrice={updateFloorPrice}
              key={selectedProduct.id}
            />

            <DeleteListingModal
              open={deleteListingModalOpen}
              setOpen={setDeleteListingModalOpen}
              product={selectedProduct}
              key={selectedProduct.id}
            />

            <PriceDropModal
              open={priceDropModalOpen}
              setOpen={setPriceDropModalOpen}
              product={selectedProduct}
              key={selectedProduct.id}
            />

            <OffersToLikersModal
              open={offersToLikersModalOpen}
              setOpen={setOffersToLikersModalOpen}
              product={selectedProduct}
              key={selectedProduct.id}
            />
          </>
        )}
      </div>
      <div className="flex flex-col items-center justify-between gap-y-6 p-[1.6rem] lg:flex-row lg:gap-y-0">
        <h1 className="text-[2.4rem] font-semibold">Listings</h1>
        <div className="flex flex-col gap-[1.6rem] sm:flex-row">
          <div className="flex items-center gap-x-2 rounded-2xl bg-[#EDEDED] px-6 py-4 font-medium text-black">
            <input
              type="text"
              className="h-full w-full border-none bg-transparent text-[0.5rem] outline-none lg:text-[1.5rem]"
              placeholder="Search live listings..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            <IoSearchOutline className="h-10 w-10 text-zinc-500 lg:h-8 lg:w-8" />
          </div>

          <Link
            href={
              userDoc?.roles?.integrations
                ? '/dashboard/integrations'
                : '/integrations'
            }
            className="flex items-center justify-center gap-x-4 rounded-2xl bg-[#EDEDED] px-8 py-4 font-medium text-black transition-all duration-150 hover:bg-[#d9d9d9] lg:justify-start"
          >
            <FiRefreshCw /> Sync Listings
          </Link>

          <Link
            href={'/create-listing'}
            className="flex items-center justify-center gap-x-2 rounded-2xl bg-brand-secondary px-8 py-4 font-medium text-white transition-all duration-150 hover:bg-brand-secondary-darker lg:justify-start"
          >
            <PlusIcon /> Sell
          </Link>

          {userDoc?.username === 'MotorPsychoSport' && (
            <Button
              type="tertiary"
              text="Export Listings to CSV"
              width="small"
              leadingIcon={<DownloadIcon />}
              onClick={async () => {
                if (userDoc) {
                  await exportListings({ userDoc });
                  queryClient.invalidateQueries({
                    queryKey: ['authUser'],
                  });
                }
              }}
            />
          )}
        </div>
      </div>
      <Divider />
      <div className="flex w-full flex-col items-start  lg:justify-between">
        <div>
          <ListingFilters
            view={view}
            setView={setView}
            filters={{
              category,
              category1,
              condition: condition as Condition,
              availability: availability as Availability,
              brand,
              auctionName: auction ?? undefined,
            }}
            setCategory={setCategory}
            setCategory1={setCategory1}
            setBrand={setBrand}
            setCondition={setCondition}
            setAvailability={setAvailability}
            setAuction={setAuction}
            limit={limit}
            setLimit={setLimit}
          />
        </div>
      </div>
      {!debouncedSearchTerm ? (
        isLoading && products?.length === 0 ? (
          // skeleton
          <div className="mt-4 flex h-full w-full grow flex-col items-center justify-center">
            <div className="flex w-full animate-pulse flex-col gap-[3.2rem]">
              <div className="flex w-full flex-col gap-2">
                <div className="h-4 w-full rounded bg-gray-300"></div>
                <div className="h-4 w-1/2 rounded bg-gray-300"></div>
              </div>
              <div className="flex w-full flex-col gap-2">
                <div className="h-4 w-full rounded bg-gray-300"></div>
                <div className="h-4 w-1/2 rounded bg-gray-300"></div>
              </div>
              <div className="flex w-full flex-col gap-2">
                <div className="h-4 w-full rounded bg-gray-300"></div>
                <div className="h-4 w-1/2 rounded bg-gray-300"></div>
              </div>
              <div className="flex w-full flex-col gap-2">
                <div className="h-4 w-full rounded bg-gray-300"></div>
                <div className="h-4 w-1/2 rounded bg-gray-300"></div>
              </div>
              <div className="flex w-full flex-col gap-2">
                <div className="h-4 w-full rounded bg-gray-300"></div>
                <div className="h-4 w-1/2 rounded bg-gray-300"></div>
              </div>
            </div>
          </div>
        ) : products.length > 0 ? (
          <>
            <ProductList
              view={view}
              products={products}
              smartPricingModalOpen={smartPricingModalOpen}
              setSelectedProduct={setSelectedProduct}
              setSmartPricingModalOpen={setSmartPricingModalOpen}
              setDeleteListingModalOpen={setDeleteListingModalOpen}
              setPriceDropModalOpen={setPriceDropModalOpen}
              setSmartPricingOffModalOpen={setSmartPricingOffModalOpen}
              setOffersToLikersModalOpen={setOffersToLikersModalOpen}
            />

            <PaginatorClient
              currentPage={page}
              onPageChange={(page) => {
                setPage(page);
              }}
              totalPages={data?.pageInfo.totalPages ?? 1}
            />
          </>
        ) : (
          <div className="mt-4 flex h-full w-full grow flex-col items-center justify-center py-32">
            <div className="flex flex-col items-center gap-y-4">
              <IoDocumentTextOutline className="h-32 w-32" />

              <h1 className="text-[2rem] font-semibold">
                You have no items for sale
              </h1>

              <span className="text-[1.8rem] text-zinc-600">
                Start selling today!
              </span>

              <Link
                href={'/create-listing'}
                className="translation-all mt-8 flex cursor-pointer items-center gap-x-2 rounded-2xl border-none bg-brand-secondary px-8 py-4 text-[1.8rem] text-white duration-150 hover:bg-brand-secondary-darker"
              >
                <PlusIcon className="h-12 w-12" />
                Sell
              </Link>
            </div>
          </div>
        )
      ) : (
        <InstantSearch searchClient={searchClient} indexName={index}>
          {/* @ts-ignore not sure why this doesn't work w typescript */}
          <Configure filters={getFilters()} hitsPerPage={250} />
          <SearchResults
            debouncedSearchTerm={debouncedSearchTerm}
            smartPricingModalOpen={smartPricingModalOpen}
            setSelectedProduct={setSelectedProduct}
            setSmartPricingModalOpen={setSmartPricingModalOpen}
            setDeleteListingModalOpen={setDeleteListingModalOpen}
            setPriceDropModalOpen={setPriceDropModalOpen}
            setSmartPricingOffModalOpen={setSmartPricingOffModalOpen}
            setOffersToLikersModalOpen={setOffersToLikersModalOpen}
          />
        </InstantSearch>
      )}
      <Divider />
    </div>
  );
};

function SearchResults({
  debouncedSearchTerm,
  smartPricingModalOpen,
  setSelectedProduct,
  setSmartPricingModalOpen,
  setDeleteListingModalOpen,
  setPriceDropModalOpen,
  setSmartPricingOffModalOpen,
  setOffersToLikersModalOpen,
}: {
  debouncedSearchTerm: any;
  smartPricingModalOpen: boolean;
  setSelectedProduct: any;
  setSmartPricingModalOpen: any;
  setDeleteListingModalOpen: any;
  setPriceDropModalOpen: any;
  setSmartPricingOffModalOpen: any;
  setOffersToLikersModalOpen: any;
}) {
  const { hits } = useHits<ProductDocument>();
  const { refine } = useSearchBox();

  useEffect(() => {
    refine(debouncedSearchTerm);
  }, [debouncedSearchTerm, refine]);

  const index = 'products_alias';
  return (
    <Index indexName={index}>
      {hits.length > 0 ? (
        <ProductList
          products={hits}
          smartPricingModalOpen={smartPricingModalOpen}
          setSelectedProduct={setSelectedProduct}
          setSmartPricingModalOpen={setSmartPricingModalOpen}
          setDeleteListingModalOpen={setDeleteListingModalOpen}
          setPriceDropModalOpen={setPriceDropModalOpen}
          setSmartPricingOffModalOpen={setSmartPricingOffModalOpen}
          setOffersToLikersModalOpen={setOffersToLikersModalOpen}
        />
      ) : (
        <div className="mx-auto mt-[1.6rem] w-[50rem] py-32">
          <EmptyState
            icon={<IoDocumentTextOutline className="h-32 w-32" />}
            title={'No listings matching your search results'}
            description={'Draft / Sold listings cannot be searched.'}
          />
        </div>
      )}
    </Index>
  );
}
function getCatDropdowns(context: {
  queryKey: string[];
  signal: AbortSignal;
  meta: Record<string, unknown> | undefined;
}): unknown {
  throw new Error('Function not implemented.');
}
