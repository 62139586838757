import Button from '@ui/Button';
import { ProductDocument, Variation } from 'models/product';
import MakeAnOfferModal from './MakeAnOfferModal';
import { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { useStripeContext } from 'context/StripeContext';
import MobileMakeAnOfferModal from './MobileMakeAnOfferModal';
import { isMobile } from '@util/index';
import { useToastContext } from 'context/ToastContext';
import { useAuth } from 'context/AuthContext';
import { usePathname, useRouter } from 'next/navigation';
import { hasValidVariations } from '@util/firestore/products';

interface MakeAnOfferButtonProps {
  product: ProductDocument;
  selectedVariations: Variation[] | null;
}

export default function MakeAnOfferButton({
  product,
  selectedVariations,
}: MakeAnOfferButtonProps) {
  const { userDoc, user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const { stripePromise } = useStripeContext();
  const { showErrorToast } = useToastContext();
  const router = useRouter();
  const pathname = usePathname();
  return (
    <>
      <Button
        type="outline"
        width="fluid"
        text="Make An Offer"
        disabled={product.out_of_stock}
        onClick={() => {
          if (userDoc && !user?.isAnonymous) {
            if (!user?.phoneNumber) {
              router.push('/verify-phone');
              return;
            }
            if (hasValidVariations(product, selectedVariations)) {
              setIsOpen(true);
            } else {
              showErrorToast('Please select a size');
            }
          } else {
            router.push(`/login?redirect=${encodeURI(pathname ?? '')}`);
          }
        }}
      />
      <Elements stripe={stripePromise}>
        {isMobile() ? (
          <MobileMakeAnOfferModal
            product={product}
            show={isOpen}
            dismiss={() => setIsOpen(false)}
            selectedVariations={selectedVariations}
          />
        ) : (
          <MakeAnOfferModal
            product={product}
            isOpen={isOpen}
            dismiss={() => setIsOpen(false)}
            selectedVariations={selectedVariations}
          />
        )}
      </Elements>
    </>
  );
}
