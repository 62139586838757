'use client';
import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';

const styles = cva('block mb-[0.8rem] items-center font-semibold text-black', {
  variants: {
    required: {
      true: 'after:content-["*"] after:ml-0.5 after:text-red-500',
    },
    flex: {
      true: 'flex items-center justify-between',
      default: 'block',
    },
  },
  defaultVariants: {
    required: false,
    flex: 'default',
  },
});

interface Props extends VariantProps<typeof styles> {
  value: string;
  children: React.ReactNode;
  errorMessage?: string;
  labelClasses?: string;
  className?: string;
  errorClassName?: string;
}

const FormLabel = ({
  children,
  value,
  errorMessage,
  required,
  labelClasses,
  className,
  errorClassName,
}: Props) => {
  return (
    <div className={`w-full items-center text-input ${className}`}>
      <span className={styles({ required }) + ' ' + labelClasses}>{value}</span>
      {!!errorMessage && (
        <span
          className={`max-w-[18.75rem] text-[1.2rem] text-brand-red ${errorClassName}`}
        >
          {errorMessage}
        </span>
      )}

      {children}
    </div>
  );
};

export default FormLabel;
