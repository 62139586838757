import { z } from 'zod';
import { addressSchema } from './address';
import { paymentSchema } from './payment';
import { dateTimeSchema, uidSchema, variationSelectionSchema } from './shared';
import { CarrierCode } from '@util/maps/carriers';
import { couponSchema } from './coupon';

export const OrderStates = [
  'Awaiting Shipment',
  'In Transit',
  'Delivered',
  'Complete',
  'Cancelled',
  'Refunded',
  'Delivery Error',
  'On Hold',
] as const;

export enum OrderStatesEnum {
  AwaitingShipment = 0,
  InTransit = 1,
  Delivered = 2,
  Complete = 3,
  Cancelled = 4,
  Refunded = 5,
  DeliveryError = 6,
  OnHold = 7,
}

export const OrderTotalStates = [
  // 0-Order Placed 1-Delivered 2-Complete, 3- On Hold, 4- Return Requested
  'Order Placed',
  'Delivered',
  'Complete',
  'On Hold',
  'Return Requested',
];

export const OrderTotalStatesEnum = {
  OrderPlaced: 0,
  Delivered: 1,
  Complete: 2,
  OnHold: 3,
  ReturnRequested: 4,
};

export enum TransferErrorType {
  'EXCEEDED_SOURCE_AMOUNT' = 1,
  'TIMEOUT' = 2,
  'UNKNOWN' = 3,
}

export enum LabelErrorType {
  'UNKNOWN' = 1,
}

export const OrderNoteSchema = z
  .object({
    text: z.string(),
    created: z.number().optional().default(Date.now()),
    uid: z.string().optional(),
  })
  .optional();

export type OrderNoteType = z.infer<typeof OrderNoteSchema>;
export type OrderStateType = (typeof OrderStates)[number];
export type OrderTotalStateType = (typeof OrderTotalStates)[number];

export const refundReasons = z.union([
  z.literal('requested_by_customer'),
  z.literal('duplicate'),
  z.literal('fraudulent'),
  z.literal('requested_by_seller'),
  z.literal('product_return'),
  z.literal('cancelled_order'),
  z.literal('item_no_longer_in_stock'),
  z.literal('shipping_complications'),
  z.literal('out_of_town'),
]);

const transferErrorSchema = z.union([
  z.literal('EXCEEDED_SOURCE_AMOUNT'),
  z.literal('TIMEOUT'),
  z.literal('UNKNOWN'),
  z.literal('TOO_SOON'),
]);

export const transferSchema = z.object({
  id: z.string().optional(),
  type: transferErrorSchema.default('UNKNOWN'),
  created: dateTimeSchema.default(Date.now()),
  last_updated: dateTimeSchema.default(Date.now()),
  status: z
    .union([z.literal('pending'), z.literal('completed')])
    .default('pending'),
  destination: z.string(), // from here down is stripe
  description: z.string(),
  source_transaction: z.string().optional(),
  metadata: z.object({
    method: z.string(),
    orderId: z.string(),
    orderNum: z.preprocess(
      (x) => (typeof x === 'string' ? parseInt(x) : x),
      z.number()
    ),
  }),
  amount: z.preprocess(
    (x) => (typeof x === 'string' ? parseInt(x) : x),
    z.number()
  ),
  currency: z.string(),
});
export const orderItemSchema = z.object({
  account_id: z.string().default(''), // shouldn't be optional but we don't want parse to throw. total_state would set to 3
  attribution: z
    .object({
      data_source: z.string().optional(),
      page: z.string().optional(),
      section: z.string().optional(),
    })
    .default({}),
  custom_inputs: z
    .record(z.string(), z.union([z.string(), z.number()]))
    .optional(),
  buyer_shipping_cost: z.number().default(0),
  carrier: z
    .string()
    .transform((x) => x.toLowerCase() as CarrierCode)
    .optional(),
  customs_form: z.string().optional(),
  dropped_off: z.boolean().optional(),
  delivery_time: dateTimeSchema.optional(),
  id: z.string().min(10),
  insurance_amount: z.number().default(0),
  return_label_id: z.string().optional(),
  label_id: z.string().optional(),
  label_pdf: z.string().optional(),
  label_png: z.string().optional(),
  marked_as_received: dateTimeSchema.optional(),
  notified_time: z
    .object({
      first: z.number().optional(),
      second: z.number().optional(),
      third: z.number().optional(),
    })
    .default({}),
  product_cost: z.number().gte(1),
  product_created: dateTimeSchema.optional(),
  product_name: z.string().optional(),
  product_tax: z.number().default(0),
  qty: z.number().gte(1).default(1),
  rate_id: z.string().optional(),
  refund_amount: z.number().default(0),
  refund_reason: refundReasons.optional(),
  service_code: z.string().optional(),
  seller_id: uidSchema,
  seller_shipping_cost: z.number().optional(),
  ship_engine_pi: z.string().optional(),
  state: z.number().min(0).max(12).default(0), // 0-Awaiting Shipment, 1-In Transit, 2-Delivered, 3-Complete, 4-Cancelled (Seller Couldn't fulfill Order), 5-Refunded (Buyer asks, we issue refund), 6-Delivery Error, 7-On Hold
  time_to_ship: z.number().optional(),
  tracking_number: z.string().optional(),
  variations: variationSelectionSchema.optional(),
});

export const orderCouponSchema = z.object({
  id: z.string().default(''),
  code: z.string().default(''),
  off: z.number().default(0),
  seller_id: z.string().optional(),
});

export const orderSchema = z.object({
  address: addressSchema,
  affirm_fee: z.number().default(0),
  buyer_id: uidSchema,
  contact_checklist: z
    .array(
      z.object({
        uid: uidSchema,
        email: z.boolean().default(false).optional(),
        phone: z.boolean().default(false).optional(),
      })
    )
    .optional(),
  coupon: orderCouponSchema.optional(),
  created: dateTimeSchema.default(Date.now()),
  customer_id: z.string().default(''),
  donate: z.boolean().default(false),
  from_web: z.boolean().default(true),
  has_promo: z.boolean().default(false),
  id: z.string().min(10),
  is_auction: z.boolean().default(false),
  is_offer: z.boolean().default(false),
  is_guest: z.boolean().default(false),
  item_count: z.number().default(1),
  label_error: z
    .object({ message: z.string(), rate_id: z.string() })
    .optional(),
  listing_id: z.number().optional(), // uship id
  notes: z
    .array(
      z.object({
        text: z.string(),
        created: dateTimeSchema.default(Date.now()),
        uid: uidSchema.optional(),
      })
    )
    .optional(),
  offer_id: z.string().optional(),
  order_num: z.number().min(10_000).max(10_000_000).default(10_000),
  payment_id: z.string().default(''),
  payment: paymentSchema.default({ type: 0 }),
  product_ids: z.array(z.string()).default([]),
  ra_fee: z.number().gte(0).default(0),
  reviewed_sellers: z.array(z.string()).default([]),
  seller_arr: z.array(z.string()).default([]),
  sellers: z.record(uidSchema, z.array(orderItemSchema)),
  shipping_total: z.number().default(0),
  states: z.array(z.number()).default([0]),
  subtotal: z.number().default(0),
  tax: z.number().default(0),
  tns: z.array(z.string()).default([]),
  total_state: z.number().min(0).max(5).default(0), // 0-Order Placed 1-Delivered 2-Complete, 3- On Hold, 4- Return Requested
  total: z.number().gte(0.5),
  tracking_error: z.number().optional(), // 1- Delivery date isn't 12 hours after orderCreate, 2-zips dont match, 3-carrier not connected
  transfer_error: z
    .object({
      type: transferErrorSchema,
      message: z.string(),
      request_id: z.string(),
      account_id: z.string().optional(),
    })
    .optional(),
  updated: dateTimeSchema.optional(),
  uship_status: z.number().optional(), // check uship.model.ts for num types
});

export const orderExportSchema = z.object({
  saleDate: z.boolean().optional(),
  orderNumber: z.boolean().optional(),
  itemTitle: z.boolean().optional(),
  itemQty: z.boolean().optional(),
  itemState: z.boolean().optional(),
  buyerName: z.boolean().optional(),
  buyerEmail: z.boolean().optional(),
  grossSellingPrice: z.boolean().optional(),
  processing: z.boolean().optional(),
  tax: z.boolean().optional(),
  taxRemitted: z.boolean().optional(),
  mxLockerFee: z.boolean().optional(),
  customerPhoneNumber: z.boolean().optional(),
  sku: z.boolean().optional(),
  shippingPrice: z.boolean().optional(),
  expeditedShipping: z.boolean().optional(),
  addressLineOne: z.boolean().optional(),
  addressLineTwo: z.boolean().optional(),
  city: z.boolean().optional(),
  stateProvince: z.boolean().optional(),
  postalCode: z.boolean().optional(),
  country: z.boolean().optional(),
});

export type OrderExportRow = {
  saleDate?: string;
  orderNumber?: number;
  itemTitle?: string;
  itemQty?: number;
  buyerName?: string;
  buyerAddress?: string;
  buyerEmail?: string;
  grossSellingPrice?: number;
  taxRemitted?: number;
  mxLockerFee?: number;
  tax?: number;
  customerPhoneNumber?: string;
  sku?: string;
  shippingPrice?: number;
  shippingInstructions?: string;
  addressLine_one?: string;
  addressLine_two?: string;
  city?: string;
  stateProvince?: string;
  postalCode?: string;
  country?: string;
};

export interface OrderItemDocument extends z.output<typeof orderItemSchema> {}
export interface OrderItemInput extends z.input<typeof orderItemSchema> {}
export interface OrderDocument extends z.output<typeof orderSchema> {}
export interface OrderInput extends z.input<typeof orderSchema> {
  created: number;
}
export interface TransferObject extends z.input<typeof transferSchema> {}
