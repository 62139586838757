import { FedExIcon, USPSIcon } from '@c/icons';
import { UPS } from '@c/icons/carriers';
import { AddressDocument } from '@models/address';
import { ProductDocument } from '@models/product';
import SafeImage from '@ui/SafeImage';
import { Rate } from '@util/firestore/shipengine';
import { useRates } from '@util/hooks/useRates';
import { formatCurrency, isMobile } from '@util/index';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

function ItemShippingSelect({
  product,
  shipment,
  shipTo,
  hideProduct = false,
  chooseCheapestByDefault = false,
  hide = false,
  onOptionSelected,
  selectedRateId,
  offer_amount,
  customsInfo,
  onError,
  onRatesLoaded,
}: {
  product?: ProductDocument;
  shipment?: ProductDocument['shipment'];
  shipTo: AddressDocument;
  hideProduct?: boolean;
  chooseCheapestByDefault?: boolean;
  hide?: boolean;
  onOptionSelected?: ({
    rate,
    product_id,
    seller_id,
  }: {
    rate: Rate;
    product_id: string;
    seller_id: string;
  }) => void;
  selectedRateId: string | null;
  offer_amount?: number;
  customsInfo?: {
    product_title: string;
    product_category: string;
    product_sku: string;
    product_price: number;
  };
  onError?: (error: Error) => void;
  onRatesLoaded?: () => void;
}) {
  const {
    rates,
    ratesError,
    ratesLoading,
    selectOption,
    foundRate,
    getFlatRateShipping,
  } = useRates({
    product,
    shipment,
    shipTo,
    chooseCheapestByDefault,
    offer_amount,
    customsInfo,
    onOptionSelected,
    selectedRateId,
  });

  const pathname = usePathname();

  useEffect(() => {
    if (ratesError) {
      onError?.(ratesError as Error);
    }
  }, [ratesError, onError]);

  if (hide) return null;

  const handleOptionSelect = (option: Rate) => {
    selectOption(option);

    onOptionSelected?.({
      rate: option,
      product_id: product?.id ?? '',
      seller_id: product?.seller_id ?? '',
    });
  };

  const flatRate = getFlatRateShipping();

  useEffect(() => {
    if (rates?.length && !ratesLoading) {
      onRatesLoaded?.();
    }
  }, [rates, ratesLoading]);

  return (
    <div className="h-full">
      {product && !hideProduct && (
        <div className={`my-4 flex items-center gap-x-4`}>
          <SafeImage
            alt={product.title}
            src={product.thumbnail}
            height={100}
            width={100}
            className="aspect-square h-32 w-32 rounded-2xl object-cover"
          />

          <div className={`flex w-full items-start justify-between`}>
            <div className="flex flex-col gap-y-2">
              <h5 className="line-clamp-1 w-[25rem] whitespace-pre-wrap text-[1.6rem] font-semibold sm:w-[28rem]">
                {product.title}
              </h5>

              <div className="flex items-center gap-x-2">
                <span className="text-elipsis line-clamp-1 text-[1.6rem] font-medium text-brand-dark-gray sm:pr-10">
                  {product.category1 === 'Boots' ||
                  product.category1 === 'Helmets'
                    ? product.category1
                    : product.category2
                    ? product.category2
                    : product.location_display
                    ? product.location_display
                    : product.category1
                    ? product.category1
                    : product.category}
                  {product.size && (
                    <>
                      {product.size?.letter
                        ? `, Size: ${product.size?.letter}`
                        : ''}
                      {product.size?.number
                        ? `${product.size?.letter ? '/ ' : ''} Size: ${
                            product.size?.number
                          }`
                        : ''}
                      {!!product.variations?.length &&
                      product.variations.length > 1
                        ? ', Size: Multiple Sizes'
                        : ''}
                    </>
                  )}
                </span>
              </div>
            </div>

            <div className="flex flex-col items-end gap-y-2">
              <p className="text-[1.6rem] font-semibold">
                {formatCurrency(product.price)}
              </p>

              <p className="text-[1.6rem] text-zinc-500">
                Qty: {product.quantity ?? 1}
              </p>
            </div>
          </div>
        </div>
      )}
      {/* options */}
      <div>
        {/* flat rate */}
        {product?.is_flat_rate === false || shipment ? (
          // multiple options
          <div className="flex flex-col gap-y-4" key={product?.id}>
            {/* shipping rate options */}
            {ratesLoading && (
              <div className="mt-8 flex w-full flex-col gap-4">
                <div className="relative h-[30rem] w-full rounded-[1rem] bg-brand-lightest-gray p-12 sm:h-[55rem]">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <span className="animate-pulse text-[1.8rem] font-medium text-brand-gray">
                      Loading Shipping Options...
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div className={`flex flex-col gap-y-4`}>
              {!!rates?.length &&
                rates.map((option) => {
                  const selected = foundRate?.rate_id === option.rate_id;

                  const delivery_days =
                    option.delivery_days ??
                    (rates.find((r) => r.service_code === 'ups_ground')
                      ?.delivery_days ?? 5) + 1;

                  return (
                    <button
                      className={`flex w-full gap-[1.6rem] rounded-[2rem] p-6 ${
                        selected
                          ? 'border border-brand-secondary bg-white'
                          : 'bg-[#F6F6F6]'
                      }`}
                      type="button"
                      onClick={() => {
                        handleOptionSelect(option);
                      }}
                      key={option.rate_id}
                    >
                      <div className="w-[6rem]">
                        {option.carrier_code === 'fedex' ? (
                          <FedExIcon className="h-16 w-16" />
                        ) : option.carrier_code === 'ups' ? (
                          <UPS className="h-16 w-16" />
                        ) : (
                          <USPSIcon className="h-16 w-16" />
                        )}
                      </div>

                      <div className="flex grow items-center gap-5">
                        <div className="flex flex-col items-start">
                          <h5 className="text-[1.6rem] font-semibold text-brand-secondary">
                            {delivery_days}{' '}
                            {delivery_days === 1 ? 'Day' : 'Days'}
                          </h5>

                          <p className="text-[1.4rem] text-brand-gray">
                            {option.service_type}
                          </p>
                        </div>

                        <div className="ml-auto text-[1.6rem] font-semibold text-black">
                          {formatCurrency(option.total_amount)}
                        </div>
                      </div>
                    </button>
                  );
                })}
            </div>

            {/* no shipping rates found */}
            {(ratesError as Error) && !ratesLoading && (
              <div className="text-center text-brand-red">
                {(ratesError as Error).message}
              </div>
            )}
          </div>
        ) : (
          <div className="mt-8 flex w-full items-center justify-between rounded-2xl bg-brand-off-white p-4">
            {flatRate === null ? (
              <div>
                <h5 className=" text-[1.6rem] font-semibold">
                  Error: No Shipping Available
                </h5>
                <p className="text-[1.4rem] text-brand-gray">
                  Please contact the seller to add shipping to your country
                </p>
              </div>
            ) : (
              <>
                <div>
                  <h5 className="text-[1.6rem] font-semibold text-brand-secondary">
                    Flat Rate Shipping
                  </h5>
                  <p className="text-[1.4rem] text-brand-gray">
                    Shipping carrier selected by seller
                  </p>
                </div>
                <div className="text-[1.6rem] font-semibold">{flatRate}</div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ItemShippingSelect;
