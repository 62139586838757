import { CloseIcon, PlusIcon } from '@c/icons';
import BaseModal from '@c/modals/BaseModal';
import { ArrowUpRight } from 'lucide-react';

import FormSelect from '@c/forms/controls/FormSelect';
import FormTextarea from '@c/forms/controls/FormTextarea';
import { DeletePhotoIcon } from '@c/listing/icons/DeletePhotoIcon';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import Spinner from '@ui/Spinner';
import { createChat } from '@util/firestore/offers';
import useMultiImageUpload from '@util/hooks/useMultiImageUpload';
import { cx } from 'class-variance-authority';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';
import { ChatDocument } from 'models/chat';
import { ProductDocument } from 'models/product';
import { BOOT_SIZES } from 'models/shared';
import { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import { formatCurrency } from '@util/index';
import FormCurrencyInputFancy from '@c/forms/controls/FormCurrencyInputFancy';
import { useRouter } from 'next/navigation';
export default function TradeInProgram() {
  const [openModal, setOpenModal] = useState<'' | 'trade-in' | 'instant-offer'>(
    ''
  );
  const { userDoc } = useAuth();
  const router = useRouter();
  return (
    <div className="w-full">
      <BootTradeInModal
        isOpen={openModal === 'trade-in'}
        dismiss={() => setOpenModal('')}
      />
      <InstantOfferModal
        isOpen={openModal === 'instant-offer'}
        dismiss={() => setOpenModal('')}
      />
      {/* preload in the modal background */}
      <div className="hidden bg-boot-trade-in" />
      <div className="mx-auto flex w-full flex-col gap-[3rem] px-[2rem] pb-8 pt-4 text-start sm:max-w-[150rem] sm:flex-row sm:px-0 sm:py-[5rem]">
        <button
          type="button"
          onClick={() =>
            userDoc ? setOpenModal('trade-in') : router.push('/signup')
          }
          className="group flex w-full  gap-[3rem] rounded-[1.5rem]  bg-[#F1F1F1] p-[2rem] text-start transition-colors hover:bg-[#444444] hover:text-white sm:p-[3rem]"
        >
          <div className="flex w-full flex-col gap-[0.5rem]">
            <span className="w-full font-bold sm:text-[2.5rem] sm:leading-[3.75rem]">
              Boot Trade-In Program
            </span>
            <span className="text-[#444444] group-hover:text-white sm:text-[2rem] sm:leading-[3rem]">
              Turn your used pair, into new.
            </span>
          </div>
          <ArrowUpRight />
        </button>
        <button
          type="button"
          onClick={() =>
            userDoc ? setOpenModal('instant-offer') : router.push('/signup')
          }
          className="group flex w-full  gap-[3rem] rounded-[1.5rem]  bg-[#444444] p-[2rem] text-start text-white transition-colors hover:bg-[#F1F1F1] hover:text-[#444444] sm:p-[3rem]"
        >
          <div className="flex w-full flex-col gap-[0.5rem]">
            <span className="w-full font-bold group-hover:text-black sm:text-[2.5rem] sm:leading-[3.75rem]">
              Get An Instant Offer
            </span>
            <span className="group-hover:text-[#444444] sm:text-[2rem] sm:leading-[3rem]">
              Sell your item fast with competitive prices.
            </span>
          </div>
          <ArrowUpRight />
        </button>
      </div>
    </div>
  );
}

const formSchema = z.object({
  photos: z
    .array(
      z.object({
        full: z.string(),
        thumb: z.string(),
      })
    )
    .min(3, 'Must have at least 3 photos'),
  currentSize: z.number().min(1, 'Please select a size'),
  request: z.string().min(1, 'Please enter a request'),
});
type Form = z.infer<typeof formSchema>;
function BootTradeInModal({
  isOpen,
  dismiss,
}: {
  isOpen: boolean;
  dismiss: () => void;
}) {
  const { openChatDrawer } = useChat();
  const { userDoc } = useAuth();
  const form = useForm<Form>({
    defaultValues: {
      photos: [],
      request: '',
    },
    resolver: zodResolver(formSchema),
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function onValid(form: Form) {
    if (!userDoc) return;
    setIsSubmitting(true);
    const content = `New Boot Trade-In Request: Trading in size ${form.currentSize} | Requesting: ${form.request}`;
    const newChat: Omit<ChatDocument, 'id'> = {
      buyer_id: 'FjBH0nwiHgGV4Y0EXDQS', // MX Locker Account (not support)
      seller_id: userDoc.uid,
      uids: ['FjBH0nwiHgGV4Y0EXDQS', userDoc.uid],
      last_time: Date.now(),
      created_at: Date.now(),
      messages: [
        {
          uid: userDoc.uid,
          content: content,
          created_at: Date.now(),
          is_img: false,
          is_auto: false,
        },
        ...form.photos.map((photo) => ({
          uid: userDoc.uid,
          is_img: true,
          content: photo.full,
          thumbnail: photo.thumb,
          created_at: Date.now(),
          is_auto: false,
        })),
      ],
      unread: {
        ['FjBH0nwiHgGV4Y0EXDQS']: true,
      },
      from_web: true,
      flagged: false,
      is_expert: false,
      is_offer: false,
    };
    try {
      const id = await createChat(newChat);
      const chatDoc: ChatDocument = {
        ...newChat,
        id,
      };
      dismiss();
      openChatDrawer(chatDoc);
    } catch (e) {
      alert((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  }

  function error(errors: any) {
    console.log(errors);
  }

  return (
    <BaseModal isOpen={isOpen} hideCloseIcon dismiss={dismiss}>
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <div className="relative hidden h-[85.875rem] w-[55rem] sm:block">
          <div className="h-full w-full bg-boot-trade-in  bg-cover bg-no-repeat" />
        </div>
        <form
          onSubmit={form.handleSubmit(onValid, error)}
          className="flex flex-col gap-[3rem] p-[2rem] sm:h-[85.875rem] sm:w-[55rem] sm:p-[3rem]"
        >
          <div className="flex  w-full justify-between font-bold sm:text-[3rem] sm:leading-[3.75rem]">
            <span className="">Boot Trade In Program</span>
            <button type="button" onClick={dismiss}>
              <CloseIcon />
            </button>
          </div>
          <div className="flex items-start gap-[1rem]">
            <div className="flex w-full  gap-[1rem]">
              <div className="hidden sm:block">
                <DollarCoin />
              </div>
              <span className="text-[#444444] sm:text-[1.62rem] sm:leading-[2.5rem]">
                After submitting this form, a message thread with our trade in
                team will open and we will be in touch with you shortly.
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <span className="text-[2rem] font-semibold leading-[3rem]">
              Upload Photos
            </span>
            <PhotoSelect form={form} />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <span className="text-[2rem] font-semibold leading-[3rem]">
              What size are your boots?
            </span>
            <FormSelect
              error={!!form.formState.errors?.currentSize?.message}
              control={form.control}
              name="currentSize"
              allowEmpty={false}
              options={BOOT_SIZES['U'].map((s) => ({
                label: s,
                value: +s,
                id: s,
              }))}
            />
            {form.formState.errors?.currentSize?.message && (
              <p className="text-red-500">
                {form.formState.errors?.currentSize?.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <span className="text-[2rem] font-semibold leading-[3rem]">
              What kind of boots are you looking for? What size?
            </span>
            <FormTextarea
              error={!!form.formState.errors?.request?.message}
              {...form.register('request')}
            />
            {form.formState.errors?.request?.message && (
              <p className="text-red-500">
                {form.formState.errors?.request?.message}
              </p>
            )}
          </div>
          <div className="flex gap-[2rem]">
            <Button
              text="Cancel"
              type="gray"
              onClick={() => dismiss()}
              disabled={isSubmitting}
            />
            <Button
              text="Get Offer"
              type="secondary"
              width={'fluid'}
              disabled={isSubmitting}
              loading={isSubmitting}
              buttonType="submit"
            />
          </div>
        </form>
      </div>
    </BaseModal>
  );
}

const instantOfferFormSchema = z.object({
  photos: z
    .array(
      z.object({
        full: z.string(),
        thumb: z.string(),
      })
    )
    .min(3, 'Must have at least 3 photos'),
  description: z.string().min(1, 'Please enter a description'),
  price: z.number().min(1, 'Please enter a price'),
});
type InstantOfferForm = z.infer<typeof instantOfferFormSchema>;
function InstantOfferModal({
  isOpen,
  dismiss,
}: {
  isOpen: boolean;
  dismiss: () => void;
}) {
  const { openChatDrawer } = useChat();
  const { userDoc } = useAuth();
  const form = useForm<InstantOfferForm>({
    defaultValues: {
      photos: [],
    },
    resolver: zodResolver(instantOfferFormSchema),
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function onValid(form: InstantOfferForm) {
    if (!userDoc) return;
    setIsSubmitting(true);
    const content = `New Instant Offer Request: Description ${
      form.description
    } | Price: ${formatCurrency(form.price)}`;
    const newChat: Omit<ChatDocument, 'id'> = {
      buyer_id: 'FjBH0nwiHgGV4Y0EXDQS', // MX Locker Account (not support)
      seller_id: userDoc.uid,
      uids: ['FjBH0nwiHgGV4Y0EXDQS', userDoc.uid],
      last_time: Date.now(),
      created_at: Date.now(),
      messages: [
        {
          uid: userDoc.uid,
          content: content,
          created_at: Date.now(),
          is_img: false,
          is_auto: false,
        },
        ...form.photos.map((photo) => ({
          uid: userDoc.uid,
          is_img: true,
          content: photo.full,
          thumbnail: photo.thumb,
          created_at: Date.now(),
          is_auto: false,
        })),
      ],
      unread: {
        ['FjBH0nwiHgGV4Y0EXDQS']: true,
      },
      from_web: true,
      flagged: false,
      is_expert: false,
      is_offer: false,
    };
    try {
      const id = await createChat(newChat);
      const chatDoc: ChatDocument = {
        ...newChat,
        id,
      };
      dismiss();
      openChatDrawer(chatDoc);
    } catch (e) {
      alert((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  }

  function error(errors: any) {
    alert(JSON.stringify(errors));
  }

  return (
    <BaseModal isOpen={isOpen} hideCloseIcon dismiss={dismiss}>
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <div className="relative hidden h-[85.875rem] w-[55rem] sm:block">
          <div className="h-full w-full bg-instant-offer  bg-cover bg-no-repeat" />
        </div>
        <form
          onSubmit={form.handleSubmit(onValid, error)}
          className="flex flex-col gap-[3rem] p-[2rem] sm:h-[85.875rem] sm:w-[55rem] sm:p-[3rem]"
        >
          <div className="flex  w-full justify-between font-bold sm:text-[3rem] sm:leading-[3.75rem]">
            <span className="">Get an Instant Offer</span>
            <button type="button" onClick={dismiss}>
              <CloseIcon />
            </button>
          </div>
          <div className="flex items-start gap-[1rem]">
            <div className="flex w-full  gap-[1rem]">
              <div className="hidden sm:block">
                <DollarCoin />
              </div>
              <span className="text-[#444444] sm:text-[1.62rem] sm:leading-[2.5rem]">
                After submitting this form, a message thread with our trade in
                team will open and we will be in touch with you shortly.
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <span className="text-[2rem] font-semibold leading-[3rem]">
              Upload Photos
            </span>
            <PhotoSelect form={form as any} />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <span className="text-[2rem] font-semibold leading-[3rem]">
              How much are you looking to get?
            </span>
            <Controller
              control={form.control}
              name="price"
              render={({ field: { onChange } }) => {
                return (
                  <FormCurrencyInputFancy
                    error={!!form.formState.errors?.price?.message}
                    value={form.getValues('price')}
                    onChange={onChange}
                  />
                );
              }}
            />

            {form.formState.errors?.price?.message && (
              <p className="text-red-500">
                {form.formState.errors?.price?.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <span className="text-[2rem] font-semibold leading-[3rem]">
              Please describe the item in detail
            </span>
            <FormTextarea
              error={!!form.formState.errors?.description?.message}
              {...form.register('description')}
            />
            {form.formState.errors?.description?.message && (
              <p className="text-red-500">
                {form.formState.errors?.description?.message}
              </p>
            )}
          </div>
          <div className="flex gap-[2rem]">
            <Button
              text="Cancel"
              type="gray"
              onClick={() => dismiss()}
              disabled={isSubmitting}
            />
            <Button
              text="Get Offer"
              type="secondary"
              width={'fluid'}
              disabled={isSubmitting}
              loading={isSubmitting}
              buttonType="submit"
            />
          </div>
        </form>
      </div>
    </BaseModal>
  );
}

function DollarCoin() {
  return (
    <svg
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10276_122916)">
        <path
          d="M46.0229 43.204C45.7943 42.8484 45.4668 42.5672 45.0806 42.3952C44.6395 42.1977 44.1773 42.0516 43.7029 41.9596H43.6318H43.5518C43.2511 41.9004 42.9543 41.8233 42.6629 41.7285C42.454 41.665 42.2686 41.5413 42.1296 41.373C41.9779 41.1851 41.9018 40.9473 41.9162 40.7063C41.9131 40.5577 41.9472 40.4107 42.0155 40.2787C42.0838 40.1467 42.1841 40.034 42.3073 39.9508C42.6272 39.7464 43.0042 39.6499 43.3829 39.6752C43.7303 39.6813 44.0737 39.7506 44.3963 39.8796C44.7675 40.0261 45.1149 40.2268 45.4273 40.4752L46.174 38.9552C45.8985 38.7425 45.6004 38.5607 45.2851 38.413C44.9827 38.2703 44.6671 38.1571 44.3429 38.0752H44.094V36.9197H42.3162V38.0485C42.0895 38.105 41.8668 38.1762 41.6495 38.2619C41.1894 38.4781 40.8052 38.8281 40.5473 39.2663C40.2758 39.7484 40.1406 40.2954 40.1563 40.8485C40.1186 41.4186 40.2651 41.9857 40.574 42.4663C40.8136 42.8161 41.1504 43.0881 41.5429 43.2485C42.0355 43.4185 42.5408 43.5493 43.054 43.6396H43.1962C43.4493 43.6873 43.6989 43.7527 43.9429 43.8352C44.1312 43.8964 44.2977 44.0106 44.4228 44.1641C44.5571 44.3358 44.6236 44.5509 44.6096 44.7685C44.6202 44.9335 44.5871 45.0984 44.5138 45.2467C44.4404 45.3949 44.3293 45.5212 44.1917 45.6129C43.8462 45.8281 43.4425 45.9305 43.0362 45.9063C42.5781 45.9075 42.1234 45.8263 41.694 45.6663C41.272 45.5044 40.8833 45.2664 40.5473 44.964L39.6584 46.4485C39.8721 46.6918 40.1359 46.8858 40.4318 47.0174C41.0109 47.302 41.6323 47.4912 42.2718 47.5774V48.7151H44.0496V47.5418C44.2996 47.489 44.5437 47.4116 44.7784 47.3107C45.2558 47.1083 45.6593 46.7638 45.934 46.324C46.2077 45.8525 46.3432 45.3134 46.3251 44.7685C46.3567 44.2341 46.23 43.7023 45.9607 43.2396"
          fill="#E52327"
        />
        <path
          d="M54.6323 55.3945C53.0762 56.9539 51.228 58.1913 49.1933 59.036C47.1587 59.8807 44.9775 60.3161 42.7745 60.3173C40.5715 60.3184 38.3898 59.8854 36.3543 59.0429C34.3187 58.2003 32.4692 56.9649 30.9115 55.4071C30.0305 54.5327 29.2524 53.5606 28.5921 52.5096C26.5569 49.2926 25.6772 45.4788 26.0976 41.6954C26.5179 37.9119 28.2134 34.3843 30.9052 31.6925C33.5969 29.0008 37.1246 27.3053 40.908 26.8849C44.6914 26.4645 48.5053 27.3443 51.7222 29.3795C52.7732 30.0397 53.7454 30.8179 54.6198 31.6988C57.7637 34.8427 59.5299 39.1068 59.5299 43.553C59.5299 47.9991 57.7637 52.2632 54.6197 55.4071L54.6323 55.3945ZM28.611 52.5033C27.5635 51.8483 26.5954 51.0743 25.726 50.1965C22.5821 47.0526 20.8159 42.7885 20.8159 38.3424C20.8159 33.8962 22.5821 29.6322 25.7261 26.4882C28.87 23.3443 33.134 21.5781 37.5802 21.5781C42.0263 21.5781 46.2904 23.3443 49.4343 26.4882C50.312 27.3576 51.0861 28.3257 51.7411 29.3732C48.5241 27.338 44.7103 26.4582 40.9269 26.8786C37.1435 27.299 33.6158 28.9945 30.924 31.6862C28.2323 34.378 26.5368 37.9057 26.1164 41.6891C25.6961 45.4725 26.5758 49.2863 28.611 52.5033V52.5033Z"
          stroke="#E52327"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M27.1844 17.4727L14.6138 4.90198M22.1562 22.5009L12.0996 12.4444M17.1279 27.5292L9.5855 19.9868"
          stroke="#E52327"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10276_122916">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

function PhotoSelect({ form }: { form: UseFormReturn<Form> }) {
  const photos = form.watch('photos');
  const id = useMemo(() => Math.random().toString(36).substring(7), []);
  const { uploadingImage, uploadImages, deleteImage } = useMultiImageUpload({
    id,
    onUploadCallback: ({ paths, errors }) => {
      console.log(paths, errors);
      form.setValue('photos', [...photos, ...paths]);

      if (errors.length) {
        form.setError('photos', {
          type: 'manual',
          message: errors.join(', '),
        });
      }
    },
    onDeleteCallback: (paths) => form.setValue('photos', [...paths]),
    type: 'other',
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const isImageFile = acceptedFiles[0]?.type.includes('image');

      if (!isImageFile) {
        form.setError('photos', {
          type: 'manual',
          message: 'Only images are allowed',
        });
        return;
      }

      uploadImages(acceptedFiles).then(({ paths, errors }) => {
        if (paths.length) {
          form.setValue('photos', [...photos, ...paths]);
          form.trigger('photos'); // Trigger form update
        }

        if (errors.length) {
          form.setError('photos', {
            type: 'manual',
            message: errors.join(', '),
          });
        }
      });
    },
    [form, photos, uploadImages]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div className="flex w-full flex-wrap items-center gap-4">
      <AddPhotoBlock
        isUploadDisabled={false}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        uploadingImage={uploadingImage}
      />

      {photos.map((image, index) => (
        <PhotoBlock
          key={image.thumb}
          image={image}
          index={index}
          deleteImage={() => deleteImage(image, photos)}
          onMainImageSelect={() => {}}
          images={photos}
        />
      ))}

      {Array(Math.max(3 - photos.length, 0))
        .fill(null)
        .map((_, index) => (
          <EmptyPhotoBlock key={index} />
        ))}
      {form.formState.errors?.photos?.message && (
        <p className="text-red-500">{form.formState.errors?.photos?.message}</p>
      )}
    </div>
  );
}

const AddPhotoBlock = ({
  getRootProps,
  getInputProps,
  isUploadDisabled,
  uploadingImage,
}: {
  getRootProps: () => any;
  getInputProps: () => any;
  isUploadDisabled: boolean;
  uploadingImage: boolean;
}) => {
  return (
    <div
      {...getRootProps()}
      className={cx(
        'flex h-[8rem] w-[8rem] items-center justify-center rounded-2xl border-2 border-dashed',
        isUploadDisabled
          ? 'border-zinc-300 opacity-50'
          : 'cursor-pointer border-brand-secondary'
      )}
    >
      <input
        {...getInputProps()}
        type="file"
        accept="image/*"
        multiple
        className="hidden"
        disabled={isUploadDisabled}
      />

      {uploadingImage ? (
        <div className="h-8 w-8">
          <Spinner className="h-8 w-8" />
        </div>
      ) : (
        <div className="flex flex-col items-center gap-y-2">
          <PlusIcon className="h-[4rem] w-[4rem] text-brand-secondary" />
        </div>
      )}
    </div>
  );
};

const EmptyPhotoBlock = () => {
  return (
    <div className="h-[8rem] w-[8rem] rounded-2xl border-2 border-dashed border-zinc-300" />
  );
};

const PhotoBlock = ({
  image,
  index,
  deleteImage,
  onMainImageSelect,
  images,
}: {
  image: ProductDocument['images'][0];
  index: number;
  deleteImage: () => void;
  onMainImageSelect: (paths: ProductDocument['images'][0][]) => void;
  images: ProductDocument['images'][0][];
}) => {
  return (
    <div
      draggable
      onDragStart={(e) => {
        e.dataTransfer.setData('text/plain', index.toString());
      }}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={(e) => {
        const dragIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
        const dropIndex = index;
        const newImages = [...images];
        const draggedImage = newImages[dragIndex];
        newImages.splice(dragIndex, 1);
        newImages.splice(dropIndex, 0, draggedImage);
        onMainImageSelect(newImages);
      }}
      onClick={() => {
        const newImages = [...images];
        const draggedImage = newImages[index];
        if (!draggedImage) return;
        newImages.splice(index, 1);
        newImages.splice(0, 0, draggedImage);
        onMainImageSelect(newImages);
      }}
      className={cx([
        'relative aspect-square w-[8rem] cursor-pointer rounded-2xl shadow-md',
        index === 0 ? 'border-4 border-brand-secondary' : '',
      ])}
    >
      <SafeImage
        alt="product image"
        className="rounded-2xl object-cover"
        src={image.thumb}
        fallbackSrc={image.download_url}
        fill
      />
      <button
        type="button"
        className="absolute right-[.6rem] top-[.6rem] z-20 flex flex-col items-center justify-center"
        onClick={(e) => {
          e.stopPropagation();
          deleteImage();
        }}
      >
        <DeletePhotoIcon height={20} width={20} />
      </button>
    </div>
  );
};
