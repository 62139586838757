import BaseDrawer from '@c/drawers/BaseDrawer';
import { CartIcon, PlusIcon } from '@c/icons';
import CloseButton from '@c/icons/buttons/CloseButton';
import { useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import { CartItemDisplay } from '@ui/CartItemDisplay';
import SafeImage from '@ui/SafeImage';
import { functions, httpsCallable } from '@util/firebase';
import {
  formatCurrency,
  getCartItemFromProductDocument,
  isMobile,
} from '@util/index';
import { getProductSlug } from '@util/urlHelpers';
import MxReturnOption from 'app/my-cart/components/mx-return-option';
import { useAuth } from 'context/AuthContext';
import { useShoppingCart } from 'context/ShoppingCartContext';
import { ProductDocument } from 'models/product';
import Link from 'next/link';
import CartProduct from './CartProduct';
import { XIcon } from 'lucide-react';
import { getTotalDiscount } from 'app/checkout/utils/discount';
import { useToastContext } from 'context/ToastContext';
import useMyFeed from 'features/homepage/hooks/useMyFeed';

interface ShoppingCartProps {}

const useUpsell = ({ product_ids }: { product_ids: string[] }) => {
  const { userDoc } = useAuth();

  return useQuery({
    queryKey: ['upsellProducts', ...product_ids],
    queryFn: async () => {
      const { data } = await httpsCallable<
        { product_ids: string[] },
        { products: ProductDocument[] }
      >(
        functions,
        'v2_getCartUpsell'
      )({
        product_ids,
      });
      return data.products;
    },
    enabled: !!userDoc,
  });
};

const ShoppingCart = ({}: ShoppingCartProps) => {
  const {
    cartOpen,
    setCartOpen,
    cart,
    realTimeProducts,
    removeFromCart,
    increaseCartQty,
    removeCoupon,
  } = useShoppingCart();

  const { showSuccessToast } = useToastContext();

  const { data: upsellProducts, isFetching } = useUpsell({
    product_ids: cart?.product_ids ?? [],
  });

  const { data: youMayAlsoLike, isLoading } = useMyFeed();

  if (!cart) return null;

  const getCartItem = (productId: string) =>
    cart.items.find((i) => i.product_id === productId);

  const groupProductsBySeller = (products: ProductDocument[]) => {
    return products.reduce<Record<string, ProductDocument[]>>(
      (acc, product) => {
        const sellerId = product.seller_id;
        const sellerProducts = acc[sellerId] || [];
        sellerProducts.push(product);
        acc[sellerId] = sellerProducts;
        return acc;
      },
      {}
    );
  };

  const groupedProducts = groupProductsBySeller(realTimeProducts);

  // desktop version - different UI/UX
  if (!isMobile())
    return (
      <div id="desktop">
        <BaseDrawer
          show={cartOpen}
          side={'right'}
          dismiss={() => setCartOpen(false)}
        >
          <div className="flex h-full w-full flex-col gap-[2.4rem] divide-y overflow-y-auto overflow-x-clip bg-brand-white font-semibold text-brand-black sm:pb-[17rem]">
            <div className="flex w-full px-[1.6rem] pt-[2.4rem] xl:px-[2.4rem]">
              <CloseButton onClick={() => setCartOpen((prev) => !prev)} />

              <div className="flex w-full justify-center sm:justify-start">
                <h1 className="text-[1.8rem] font-semibold">Your Cart</h1>
              </div>
            </div>

            {/* empty state */}
            {!cart.items.length && (
              <div className="mt-auto flex h-full flex-col items-center justify-center">
                <div className="flex flex-col items-center gap-[3.2rem]">
                  <div className="text-brand-lighter-gray">
                    <CartIcon width={64} height={64} />
                  </div>
                  <div className="flex flex-col items-center gap-[0.8rem] p-[0.8rem]">
                    <p>No Items</p>
                    <p className="text-center font-normal">
                      You cart is empty.
                    </p>
                  </div>

                  <Button
                    onClick={() => setCartOpen((prev) => false)}
                    href="/shop"
                    text="Continue Shopping"
                    type="secondary"
                  />
                </div>
              </div>
            )}

            {/* not empty state */}
            {!!cart.items.length && (
              <>
                {/* items */}
                <div className="space-y-4 pt-[2.4rem]">
                  {Object.entries(groupedProducts).map(
                    ([sellerId, products]) => {
                      const seller = products[0]?.seller;

                      return (
                        <div
                          key={sellerId}
                          className="flex flex-col gap-y-4 px-[1.6rem] xl:px-[2.4rem]"
                        >
                          {seller && (
                            <div className="mb-4 flex items-center gap-x-4">
                              <SafeImage
                                src={seller.photo ?? ''}
                                alt={seller.username ?? ''}
                                className="h-16 w-16 rounded-full object-cover"
                                height={64}
                                width={64}
                              />
                              <span className="text-[1.6rem] font-medium text-black">
                                {seller.username}
                              </span>
                            </div>
                          )}

                          {products.map((p) => (
                            <CartItemDisplay
                              key={p.id}
                              product={p}
                              cartItem={getCartItem(p.id)}
                            />
                          ))}
                        </div>
                      );
                    }
                  )}
                </div>

                {/* total/checkout */}
                <div className="inset-x-0 bottom-0 z-50 bg-brand-white pt-[2.4rem] sm:fixed sm:mt-auto">
                  <div className="flex flex-col gap-[2rem] px-[2.4rem] pb-[2.4rem]">
                    {!!cart.coupons?.length && (
                      <>
                        <div className="flex items-center justify-between">
                          <p className="text-[1.5rem]">
                            {`Applied coupon${
                              cart.coupons.length > 1 ? 's' : ''
                            }`}
                          </p>

                          <p className="text-[1.5rem] font-semibold">
                            {cart.coupons.map((c) => (
                              <span
                                key={c.code}
                                className="flex items-center gap-x-2 rounded-full bg-brand-secondary px-4 py-1 text-white"
                              >
                                {c.code}

                                <button
                                  type="button"
                                  onClick={() => removeCoupon(c)}
                                >
                                  <XIcon className="h-6 w-6 cursor-pointer" />
                                </button>
                              </span>
                            ))}
                          </p>
                        </div>

                        <div className="flex items-center justify-between">
                          <p className="text-[1.5rem]">Discount</p>

                          <p className="text-[1.8rem] font-semibold text-brand-secondary">
                            {getTotalDiscount(cart) > 0 ? '-' : ''}
                            {formatCurrency(getTotalDiscount(cart))}
                          </p>
                        </div>
                      </>
                    )}

                    <div className="flex items-center justify-between">
                      <p className="text-[1.5rem]">Total (USD)</p>

                      <p className="text-[2.4rem]">
                        {formatCurrency(cart.total)}
                      </p>
                    </div>

                    <MxReturnOption />

                    <Button
                      text="View cart"
                      href="/my-cart"
                      height="small"
                      type="secondary"
                      onClick={() => {
                        setCartOpen((prev) => !prev);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </BaseDrawer>
      </div>
    );

  if (!realTimeProducts.length) {
    <div id="mobile">
      <BaseDrawer
        show={cartOpen}
        side={'right'}
        dismiss={() => setCartOpen(false)}
        fill={true}
      >
        <>
          <div className="flex w-full justify-between">
            <div className="flex w-full border-b border-zinc-200 px-[1.6rem] pb-8 pt-[2.4rem] xl:px-[2.4rem]">
              <CloseButton onClick={() => setCartOpen((prev) => !prev)} />

              <div className="flex w-full justify-center sm:justify-start">
                <h1 className="text-[1.8rem] font-semibold">Your Cart</h1>
              </div>
            </div>

            <div className="flex h-full w-full items-center justify-center">
              <div className="flex flex-col items-center gap-y-6">
                <CartIcon className="h-16 w-16 text-zinc-500" />

                <h3 className="text-zinc-800">Your cart is empty!</h3>

                <Button
                  text="Continue Shopping"
                  onClick={() => setCartOpen(false)}
                />
              </div>
            </div>
          </div>
        </>
      </BaseDrawer>
    </div>;
  }

  return (
    <>
      <div id="mobile">
        <BaseDrawer
          show={cartOpen}
          side={'right'}
          dismiss={() => setCartOpen(false)}
          fill={true}
        >
          <>
            <div className="flex h-[85vh] w-full flex-col ">
              <div className="flex w-full border-b border-zinc-200 px-[1.6rem] pb-8 pt-[2.4rem] xl:px-[2.4rem]">
                <CloseButton onClick={() => setCartOpen((prev) => !prev)} />

                <div className="flex w-full justify-center sm:justify-start">
                  <h1 className="text-[1.8rem] font-semibold">Your Cart</h1>
                </div>
              </div>

              {realTimeProducts.length > 0 ? (
                <>
                  <div className="flex flex-col gap-y-8 overflow-y-auto">
                    <div className="grow p-4">
                      <div className="space-y-8 px-8 pb-16">
                        {Object.values(groupedProducts).map(
                          (products, index) => (
                            <div key={index}>
                              {products.map((product, productIndex) => (
                                <CartProduct
                                  product={product}
                                  key={product.id}
                                  onRemove={() => removeFromCart(product.id)}
                                  showSellerInfo={productIndex === 0}
                                  showBottomBorder={
                                    productIndex === products.length - 1
                                  }
                                />
                              ))}
                            </div>
                          )
                        )}
                      </div>
                      {isFetching || isLoading ? (
                        <div className="flex w-full flex-col gap-y-6 px-8 pb-16 pt-4">
                          <h3 className="h-[2.2rem]  rounded-lg  text-[1.8rem] font-semibold">
                            You may also need
                          </h3>
                          <div className="flex flex-nowrap overflow-x-scroll p-4 scrollbar-none">
                            <div className="flex min-w-[75vw] items-center justify-between gap-4 rounded-[3rem] pr-2 pt-2 sm:w-full sm:min-w-0">
                              <div className="h-[10rem] w-[10rem] animate-pulse rounded-[3rem] bg-gray-500" />

                              <div className="flex grow flex-col gap-y-1">
                                <h3 className="line-clamp-2 h-[2.2rem] w-[10rem] animate-pulse whitespace-pre-wrap rounded-lg bg-gray-500 text-[1.6rem]"></h3>

                                <p
                                  className={
                                    'h-[2.2rem] w-[5rem] animate-pulse rounded-lg bg-gray-500 text-[1.6rem] font-bold'
                                  }
                                ></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : upsellProducts?.length ||
                        youMayAlsoLike?.gear.length ||
                        youMayAlsoLike?.parts.length ? (
                        <div className="flex w-full flex-col gap-y-6 px-8 pb-16 pt-4">
                          <h3 className="text-[1.8rem] font-semibold text-zinc-800">
                            You may also need
                          </h3>
                          <div className="flex flex-nowrap gap-8 overflow-x-scroll p-4 scrollbar-none">
                            {[
                              ...(upsellProducts ?? []),
                              ...(youMayAlsoLike?.gear ?? []),
                              ...(youMayAlsoLike?.parts?.[0]?.products ?? []),
                            ].map((p) => {
                              return (
                                <div
                                  className="flex min-w-[75vw] items-center justify-between gap-4 rounded-[3rem] pr-2 pt-2 shadow-md sm:w-full sm:min-w-0"
                                  key={p.id}
                                >
                                  <Link
                                    href={getProductSlug(p)}
                                    onClick={() => setCartOpen(false)}
                                  >
                                    <SafeImage
                                      src={p.thumbnail}
                                      alt={p.title}
                                      className="aspect-square h-[10rem] w-[10rem] rounded-bl-[3rem] rounded-tl-[3rem] object-cover"
                                      height={200}
                                      width={200}
                                    />
                                  </Link>

                                  <Link
                                    href={getProductSlug(p)}
                                    onClick={() => setCartOpen(false)}
                                    className="flex grow flex-col gap-y-1"
                                  >
                                    <h3 className="line-clamp-2 whitespace-pre-wrap text-[1.6rem] text-black">
                                      {p.title}
                                    </h3>

                                    <p
                                      className={
                                        'text-[1.6rem] font-bold text-black'
                                      }
                                    >
                                      {formatCurrency(p.price)}
                                    </p>
                                  </Link>
                                  <button
                                    className="z-10 flex items-center justify-center rounded-full bg-black px-4 py-4 font-semibold text-white"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      increaseCartQty(
                                        getCartItemFromProductDocument(p),
                                        true
                                      );

                                      showSuccessToast(
                                        'An item in your cart has been updated'
                                      );
                                    }}
                                  >
                                    <PlusIcon />
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="flex flex-col items-center justify-center gap-y-6 px-8 pt-4">
                    <p className="w-[40rem] text-center text-zinc-400">
                      Shipping and taxes are calculated at checkout after
                      confirming your address
                    </p>

                    <Link
                      href={'/checkout'}
                      className="flex w-full items-center justify-center rounded-[2rem] bg-[#C41618] py-6 text-[2rem] font-semibold text-white"
                      onClick={() => {
                        setCartOpen(false);
                      }}
                    >
                      <div className="flex items-center gap-x-2">
                        <span>Checkout</span>
                        <span>{formatCurrency(cart.subtotal)}</span>
                      </div>
                    </Link>
                  </div>
                </>
              ) : (
                <div className="flex h-[70rem] items-center justify-center">
                  <div className="flex flex-col items-center gap-y-8">
                    <CartIcon className="h-32 w-32 text-zinc-400" />

                    <h3 className="text-[1.8rem] font-medium text-black">
                      Your cart is empty!
                    </h3>

                    <Button
                      text="Continue shopping"
                      className="!rounded-[2rem] !py-12 text-[1.8rem] sm:rounded-lg sm:py-4"
                      type="secondary"
                      href={'/shop/all'}
                      onClick={() => setCartOpen(false)}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        </BaseDrawer>
      </div>
    </>
  );
};

export default ShoppingCart;
