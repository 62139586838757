import { capitalize, formatCurrency } from '@util/index';
import { CartItem } from '@util/types/firestore/carts';
import { ProductDocument } from 'models/product';
import Link from 'next/link';
import SafeImage from './SafeImage';
import { CartItemQuantity } from './CartItemQuantity';

export const CartItemDisplay = ({
  product,
  cartItem,
}: {
  product: ProductDocument;
  cartItem?: CartItem;
}) => {
  if (!cartItem) return null;

  return (
    <div className="flex w-full gap-4">
      <div className="relative h-[8rem] w-[8rem] shrink-0 overflow-clip rounded-md shadow-sm">
        <SafeImage
          src={cartItem.product_image}
          fill
          alt={cartItem.product_title}
          className="rounded-2xl object-cover"
        />
      </div>

      <div className="flex grow flex-col gap-2">
        <Link
          href={`/shop/${cartItem.product_link}`}
          className="text-[1.6rem] font-medium hover:text-brand-primary"
        >
          {cartItem.product_title}
        </Link>

        {/* Show variations */}
        {cartItem.size?.letter && (
          <span className="text-[1.4rem] text-brand-gray">
            Size: {cartItem.size.letter}
          </span>
        )}
        {cartItem.size?.number && (
          <span className="text-[1.4rem] text-brand-gray">
            Size: {cartItem.size.number}
          </span>
        )}
        {cartItem.color && (
          <span className="text-[1.4rem] text-brand-gray">
            Color: {cartItem.color}
          </span>
        )}

        {/* Add custom inputs display */}
        {cartItem.custom_inputs?.enabled && (
          <div className="flex flex-col gap-1">
            <span className="text-[1.4rem] font-medium text-brand-gray">
              Custom Options (+{formatCurrency(cartItem.custom_inputs.cost)}):
            </span>
            {Object.entries(cartItem.custom_inputs.values).map(
              ([key, value]) => (
                <span key={key} className="text-[1.4rem] text-brand-gray">
                  {capitalize(key)}: {value}
                </span>
              )
            )}
          </div>
        )}

        <CartItemQuantity product={product} cartItem={cartItem} />
      </div>

      <div className="flex flex-col items-end gap-2">
        <span className="text-[1.6rem] font-semibold">
          {formatCurrency(cartItem.product_cost * cartItem.qty)}
        </span>
        {cartItem.shipping_cost && (
          <span className="text-[1.4rem] text-brand-gray">
            +{formatCurrency(cartItem.shipping_cost)} shipping
          </span>
        )}
      </div>
    </div>
  );
};
